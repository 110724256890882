import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { JsonUtils } from '@ingroupe/common-utils';
import { environment } from '@env/environment.prod';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


/**
 * Configuration service
 *
 * Service to call configuration endpoint
 */
@Injectable({
  providedIn: CoreModule
})
export class ConfigurationService {
  /**
   * The application configuration
   */
  private config: any;
  private endPointConfig: any;

  /**
   * The constructor
   *
   * @param http : the http service use to made http request
   */
  public constructor( private http: HttpClient ) {

  }

  /**
   * Call global configuration api to get global app configuration
   *
   * @return Observable of boolean to indicate if is loaded
   */
  public loadGlobalConfiguration(): Promise<boolean> {
    return new Promise<boolean>(( resolve, reject ) => {
      this.http.get<any>(environment.appConfigurationPath).pipe(
        catchError(( error: HttpErrorResponse ): any => {
          const rejectMessage = 'The app configuration file could not be read';
          console.log(rejectMessage);
          reject(rejectMessage);
          return throwError(error.message || 'Server error');
        }))
        .toPromise().then(( config: any ) => {
        this.config = config;
        resolve(true);
      });
    });
  }

  /**
   * getConfig
   *
   * @description Use to get data found in second file (config file)
   * @param key key to get
   *
   * @returns any
   */
  public getConfig( key: string ): any {
    return JsonUtils.findOne(this.config, key);
  }

  /**
   * Call global configuration api to get endPoints
   *
   * @return Observable of boolean to indicate if is loaded
   */
  public loadEndPointConfiguration(): Promise<boolean> {
    return new Promise<boolean>(( resolve, reject ) => {
      this.http.get<any>(environment.appEndpointConfiguration).pipe(
        catchError(( error: HttpErrorResponse ): any => {
          const rejectMessage = 'The app configuration file could not be read';
          console.log(rejectMessage);
          reject(rejectMessage);
          return throwError(error.message || 'Server error');
        }))
        .toPromise().then(( config: any ) => {
        this.endPointConfig = config;
        resolve(true);
      });
    });
  }

  /**
   * getEndpoint
   *
   * @description Use to get data found in file (endPoint file)
   * @param key key to get
   *
   * @returns any
   */
  public getEndPoint( key: string ): any {
    return JsonUtils.findOne(this.endPointConfig, key);
  }
}
