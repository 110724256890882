import { Injectable } from '@angular/core';
import { CoreModule } from '../../core.module';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { StringUtils } from '@ingroupe/common-utils';
import { Constant } from '@models/constant/constant';
import { AnalyticsCookies } from '@models/analytics/analytics-cookies.enum';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@services/storage/local-storage.service';

@Injectable({
  providedIn: CoreModule
})
export class AnalyticsService {

  public cookieState: BehaviorSubject<boolean>;

  constructor(private config: ConfigurationService, private matomoInjector: MatomoInjector, private matomoTracker: MatomoTracker,
              private localStorageService: LocalStorageService) {
    this.cookieState = new BehaviorSubject(false);
    this.isActive();
  }

  public initAnalytics(): void {
    this.matomoInjector.init(this.config.getConfig('analytics.trackerUrl'), this.config.getConfig('analytics.siteId'));
  }

  public trackPageView(url: string): void {
    this.matomoTracker.trackPageView(url);
  }

  public activateAnalytics(): void {
    this.localStorageService.save(Constant.ANALYTICS, AnalyticsCookies.ENABLED);
    this.cookieState.next(true);
    this.initAnalytics();
  }

  public deactivateAnalytics(): void {
    this.localStorageService.save(Constant.ANALYTICS, AnalyticsCookies.DISABLED);
    this.matomoTracker.deleteCookies();
    this.cookieState.next(false);
  }

  public isActive(): boolean {
    this.cookieState.next(StringUtils.equalsIgnoreCase(this.localStorageService.find(Constant.ANALYTICS), AnalyticsCookies.ENABLED));
    return this.cookieState.value;
  }

  public isInformed(): boolean {
    return StringUtils.isNotBlank(this.localStorageService.find(Constant.ANALYTICS));
  }

}
