<div class="row no-gutters">
  <div class="col-12 col-sm-8 d-flex flex-column">
    <h2>{{'show-statement.title' | translate | capitalizeFirst}}</h2>
    <p class="text-justify mb-4" innerHtml="{{'show-statement.notice' | translate | capitalizeFirst}}"></p>

    <h3 [innerHtml]="statement.object"></h3>

    <label>{{'show-statement.labels.sender' | translate | capitalizeFirst}}</label>
    <p class="created-by">{{statement.createdBy}}</p>

    <label class="mt-2">{{'show-statement.labels.validity' | translate | capitalizeFirst}}</label>

    <div class="d-flex align-items-baseline">
      <i class="validity-state {{statementStatus}} align-self-center"></i>
      <span
        class="ml-3">{{'show-statement.labels.validity-states.' + statementStatus | translate | capitalizeFirst}}</span>
    </div>

    <div class="mt-4">
      <button class="btn btn-primary" fileSaver [method]="'GET'" [fileName]="buildFilename()" [url]="getPdfUrl()"
        translate>show-statement.labels.btn-download</button>
    </div>
  </div>
</div>