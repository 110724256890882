import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as Quill from 'quill';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements AfterViewInit, OnInit {
  /**
   * The current selected lang
   */
  @Input() customId!: string;
  @Input() isAlignButtonEnabled?: boolean;
  @Input() height?: string;
  @Input() isResetButtonEnabled?: boolean;
  @Input() maxLines?: number;
  @Input() defaultValue?: string; // for saved draft value of a free or saved by user statement
  @Input() initialValue?: string; // for default configured by admin value
  @Input() placeHolder?: string;
  @Input() displayResetButton?: boolean;
  @Input() resetButtonText?: string;
  @Output() update: EventEmitter<string> = new EventEmitter();
  @Input() invalidCountLineMessageError?: string;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();

  savedInitialValue = ''; // for saving initial value on int of componenet.
  errorShouldDisplay = false;
  richTextEditorRef = null;

  defaultToolbarOptions: any = [
    ['bold', 'italic', 'underline', 'strike']
  ];

  optionsAlignButtons = [
    {align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}
  ];

  resetField() {
    const resetToValue = this.savedInitialValue && this.savedInitialValue.length > 0 ? this.savedInitialValue : '';
    this.richTextEditorRef.setContents(
      this.richTextEditorRef.clipboard.convert(resetToValue),
      'silent'
    );
    this.update.emit(this.richTextEditorRef.container.outerHTML);
  }

  buildResetLink() {
    const span = document.createElement('span');
    span.className = 'ql-formats';
    span.style.float = 'right';

    const a = document.createElement('a');
    a.addEventListener('click', () => {
      this.resetField();
    });

    const textNode = document.createTextNode(this.resetButtonText);

    a.appendChild(textNode);
    span.appendChild(a);

    document.getElementById(this.customId).parentNode.children[0].appendChild(span);
  }

  ngOnInit() {
    this.savedInitialValue = this.initialValue;
  }

  ngAfterViewInit(): void {
    if (this.isAlignButtonEnabled) {
      this.defaultToolbarOptions.push(this.optionsAlignButtons);
    }
    this.richTextEditorRef = new Quill('#' + this.customId, {
      modules: {toolbar: this.defaultToolbarOptions},
      theme: 'snow',
      placeholder: this.placeHolder || ''
    });

    if (this.defaultValue?.length > 0) {
      this.richTextEditorRef.setContents(
        this.richTextEditorRef.clipboard.convert(this.defaultValue),
        'silent'
      );
    }

    this.richTextEditorRef.on('text-change', () => {
      this.errorShouldDisplay = this.maxLines && this.maxLines > 0 && this.richTextEditorRef.getLines().length > this.maxLines;
      this.isValid.emit(!this.errorShouldDisplay);
      this.update.emit(this.richTextEditorRef.container.innerText.length >= 1 ? this.richTextEditorRef.container.outerHTML : '');
    });

    if (this.displayResetButton) {
      this.buildResetLink();
    }
  }
}
