import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { AboutComponent } from '@pages/about/about.component';
import { FaqComponent } from '@pages/faq/faq.component';
import { HomeComponent } from '@pages/home/home.component';
import { StatementListComponent } from '@pages/statement/statement-list/statement-list.component';
import {BrowserGuard} from '@guards/browser.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [BrowserGuard] },
  { path: 'about', component: AboutComponent, canActivate: [BrowserGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [BrowserGuard] },
  {
    path: 'account',
    canActivate: [BrowserGuard],
    children: [
      {
        path: 'statement-list',
        component: StatementListComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
