import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DeviceService } from '@services/device/device.service';
import { CoreModule } from '../core.module';

/**
 * The browser guard to route to incompatible navigator page
 */
@Injectable({
    providedIn: CoreModule
})
export class BrowserGuard implements CanActivate {
    /**
     * Constructor
     *
     * @param deviceService : the device service to check the browser compatibility
     * @param router : the router to route to incompatible navigator page
     */
    constructor(private deviceService: DeviceService, private router: Router) {

    }

    /**
     * Guard route activation check
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isDeprecatedBrowser: boolean = this.deviceService.isDeprecatedBrowser();
        if (isDeprecatedBrowser) {
            return this.router.parseUrl('/incompatible-browser');
        }

        return true;
    }
}
