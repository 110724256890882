<footer class="w-100 p-3 px-md-5 flex-shrink-0 d-flex flex-column">
  <div class="row h-100 d-flex d-flex justify-content-center">
    <div
      class="col-12 col-lg-10 h-100 d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between">
      <div class="d-flex justify-content-center">
        <img src="assets/images/footer/logo.svg" class="float-left align-self-center" alt="">
      </div>

      <div class="d-flex align-items-end justify-content-center">
        <div class="d-flex flex-column">
          <nav class="mt-2 mt-sm-1 justify-content-center justify-content-md-end d-flex align-items-baseline">
            <a routerLink="/legal-notice" class="link">
              {{ "footer.links.legalMention" | translate }}
              <span class="separator"></span>
            </a>
            <a *ngIf="isAuthenticated" [routerLink]="gcuAccepted ? '/read-gcu' : '/gcu'" class="link">
              {{ "footer.links.gcu" | translate }}
              <span class="separator"></span>
            </a>
            <a routerLink="/cookies" class="link">{{ "footer.links.cookie" | translate }}
            </a>
          </nav>
          <div class="mt-2 text-center text-sm-right" translate [translateParams]="{version: appVersion, year: year}">
            footer.copyright
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
