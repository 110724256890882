import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalConfirmComponent} from '@components/modal-confirm/modal-confirm.component';
import {ObjectUtils, StringUtils} from '@ingroupe/common-utils';
import {IngroupeTranslateService} from '@ingroupe/translate';
import {Pageable} from '@models/pageable/pageable.model';
import {SortDirection} from '@models/pageable/sort-direction.enum';
import {SignedStatementContent} from '@models/statement/signed-statement-content.model';
import {SignedStatement} from '@models/statement/signed-statement.model';
import {StatementTemplateContent} from '@models/statement/Statement-template-content.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StatementService} from '@services/statement/statement.service';
import {DatePipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastNotificationService} from '@services/toast-notification/toast-notification.service';
import {SessionStorageService} from '@services/storage/session-storage.service';

@Component({
  selector: 'app-statement-list',
  templateUrl: './statement-list.component.html',
  styleUrls: ['./statement-list.component.scss']
})
export class StatementListComponent implements OnInit {
  private static readonly SESSION_STORAGE_KEY_LANG = 'lang';

  public statements: SignedStatement;
  public statementInProgress: StatementTemplateContent;
  public isCollapsed: boolean;
  public pagination: Pageable;
  public sortDirection = SortDirection;
  public sortDir: SortDirection = SortDirection.NONE;
  private initialized: boolean;

  constructor(
    private router: Router, private statementService: StatementService,
    private modalService: NgbModal, private translateService: IngroupeTranslateService, private datePipe: DatePipe,
    private toastNotificationService: ToastNotificationService, private sessionStorageService: SessionStorageService) {
    this.pagination = new Pageable();
    this.pagination.filters = {status: []};

  }

  ngOnInit(): void {
    this.initialized = true;
    this.getStatementsList();
    this.getStatementInProgress();
  }

  public getStatementsList(page?: number): void {
    if (!this.initialized) {
      return;
    }

    if (ObjectUtils.isNotNullOrUndefined(page)) {
      this.pagination.page = page - 1;
    }

    this.statementService.getSignedStatements(this.pagination).then(response => {
      this.statements = response;
      this.statements.pageable.pageNumber += 1;
    });
  }

  public createStatement(): void {
    this.router.navigate(['/account/create-statement']);
  }

  public pdfUrl(statementId: string) {
    return `/account/api/declarations/${statementId}/pdf`;
  }

  public deleteStatement(idStatement: string) {
    const modalRef = this.modalService.open(ModalConfirmComponent, {size: 'md', backdrop: 'static'});
    modalRef.componentInstance.title = this.translateService.resolveMessage('statement-list.modal-confirm.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage('statement-list.modal-confirm.message');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('statement-list.modal-confirm.cancel');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('statement-list.modal-confirm.confirm');
    modalRef.result.then(() => {
      this.statementService.deleteStatement(idStatement).then(() => {
        this.getStatementsList();
        this.getStatementInProgress();
      })
        .catch((err: HttpErrorResponse) => {
          this.handleError(err);
        });
    }, () => false);
  }

  public sortCriteriaChange(sortType: string, sortDir: SortDirection): void {
    this.pagination.sorts.clear();
    this.sortDir = sortDir;
    this.pagination.sorts.set(sortType, this.sortDir);
    this.getStatementsList(-1);
  }

  public deleteOtherSorts(sort: string): void {
    const list = (document.getElementsByClassName('form-check-input'));
    // @ts-ignore
    for (const item of list) {
      if (item.name !== sort) {
        item.checked = false;
      }
    }
  }

  public resetFilters(): void {
    this.pagination.sorts.clear();
    delete this.pagination.filters.status;

    const list = (document.getElementsByClassName('form-check-input'));
    // @ts-ignore
    for (const item of list) {
      item.checked = false;
    }
    this.getStatementsList(-1);
  }

  /**
   * Build the file name for the given statement
   *
   * @param statement : the current statement
   */
  public buildFilename(statement: SignedStatementContent): string {
    const formatedDate: string = this.datePipe.transform(statement.signDate,
      this.translateService.resolveMessage('date.filename') as string);
    return `${statement.object}_${formatedDate}.pdf`;
  }

  /**
   * handle error when getting a statement
   *
   * @param errorResponse the error
   */
  public handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 400) {
      this.toastNotificationService.error('statement-list.error.not-found');
    } else {
      this.toastNotificationService.error('statement-list.error.generic');
    }
  }

  public typeSortIsDate(sortName: string): boolean {
    return sortName.includes('Date');
  }

  private getStatementInProgress(): void {
    this.statementService.getIdDraft().then((idDraft: string) => {
      if (StringUtils.isNotBlank(idDraft)) {
        this.statementService.getStatement(idDraft).then((response: StatementTemplateContent) => {
          this.statementInProgress = response;
          this.sessionStorageService.save(
            StatementListComponent.SESSION_STORAGE_KEY_LANG,
            this.statementInProgress.lang
          );
        });
      } else {
        this.statementInProgress = null;
      }
    });
  }
}
