import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AvailableFormLang} from '@models/configuration/available-form-lang.model';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {CategoryService} from '@services/category/category.service';
import {Category} from '@models/category/category.model';
import {StatementService} from '@services/statement/statement.service';
import {IngroupeTranslateService} from '@ingroupe/translate';
import {Statement} from '@models/statement/statement.model';
import {StringUtils} from '@ingroupe/common-utils';
import {Constant} from '@models/constant/constant';
import {SessionStorageService} from '@services/storage/session-storage.service';

@Component({
  selector: 'app-create-statement',
  templateUrl: './create-statement.component.html',
  styleUrls: ['./create-statement.component.scss']
})
export class CreateStatementComponent implements OnInit {
  private static readonly SESSION_STORAGE_KEY_LANG = 'lang';

  public availableLanguages: AvailableFormLang[];
  public categories: Category[] = [];
  public statements: Statement[] = [];
  public selectedLang: AvailableFormLang;
  public templateId: string;
  public categoryId: string;
  public helpMessage: string;
  public isEmptyStatementTemplate = false;
  public isPlaceholder = true;
  public freeCategory: Category;

  constructor(private configurationService: ConfigurationService, private categoryService: CategoryService,
              private statementService: StatementService, private translateService: IngroupeTranslateService, private router: Router,
              private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    this.availableLanguages = this.configurationService.getConfig('forms.availableLanguages');
    this.templateId = null;
  }

  public getCategory(lang: AvailableFormLang): Category[] {
    this.selectedLang = lang;
    this.categories = [];
    this.statements = [];
    this.templateId = null;
    this.isEmptyStatementTemplate = false;
    this.helpMessage = null;
    const freeCategoryLabel: string = this.translateService.resolveMessage('create-statement.free-category') as string;
    this.freeCategory = {id: '', contents: [{label: freeCategoryLabel, lang: lang.lang}]};
    this.categoryService.getCategories(lang)
      .then(response => {
        this.categories = response.sort((category1, category2) => {
          const label1: string = category1.contents.find(content => content.lang === lang.lang).label.toLowerCase();
          const label2: string = category2.contents.find(content => content.lang === lang.lang).label.toLowerCase();
          return label1 < label2 ? -1 : 1;
        });
      })
      .catch((err: any) => {
        console.error(err);
      });
    this.saveLang(this.selectedLang.lang);
    this.categoryId = null;
    return;
  }

  private saveLang(selectedLang: string): void {
    this.sessionStorageService.save(CreateStatementComponent.SESSION_STORAGE_KEY_LANG, selectedLang);
  }

  public getStatementTemplates(categoryId: string): void {
    this.isEmptyStatementTemplate = false;
    this.helpMessage = null;
    this.templateId = null;
    this.isPlaceholder = false;
    if (StringUtils.isNotBlank(categoryId)) {
      this.statementService.getStatementTemplates(this.selectedLang.lang, categoryId)
        .then(response => {
          this.statements = response.sort((stat1, stat2) => {
            const label1: string = stat1.templateContents.find(content => content.lang === this.selectedLang.lang).object.toLowerCase();
            const label2: string = stat2.templateContents.find(content => content.lang === this.selectedLang.lang).object.toLowerCase();
            return label1 < label2 ? -1 : 1;
          });
          this.isEmptyStatementTemplate = this.statements.length === 0;
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else {
      const freeObjectLabel: string = this.translateService.resolveMessage('create-statement.free-statement.object') as string;
      const freeHelpObjectLabel: string = this.translateService.resolveMessage('create-statement.free-statement.help-object') as string;
      this.statements = [{
        id: Constant.STATEMENT_FREE,
        templateContents: [{
          lang: this.selectedLang.lang,
          object: freeObjectLabel,
          helpObject: freeHelpObjectLabel,
          free: true
        }]
      }];
      this.helpMessage = freeHelpObjectLabel;
      this.templateId = this.statements[0].id;
    }
  }

  public displayHelp(message: string) {
    this.helpMessage = message;
  }

  public createStatement(templateId: string): void {
    this.router.navigate(['account/statement-form', templateId]);
  }

  public isFreeDeclaration(): boolean {
    return this.statements[0].id === Constant.STATEMENT_FREE;
  }

}
