<div class="row no-gutters">
  <div class="col-12 col-sm-8 d-flex flex-column">
    <h2>{{'access-statement.title' | translate | capitalizeFirst}}</h2>

    <form #accessStatementForm="ngForm" class="d-flex flex-column">
      <div class="form-group w-75">
        <label for="codeInput">{{'access-statement.code-input.label' | translate | capitalizeFirst}} *</label>
        <input type="text" id="codeInput" class="form-control" name="codeInput" [(ngModel)]="accessCode"
          #codeInput="ngModel" placeholder="{{'access-statement.code-input.placeholder' | translate }}"
          (ngModelChange)="isInvalidCode = false" [maxlength]="accessCodeMaxLength" appLockCopyPaste required
          appUppercase appAlphanumericOnly autocomplete="off">
        <div class="text-info invalid" *ngIf="submitted">
          <span *ngIf="codeInput.invalid">
            {{'access-statement.errors.access-code.required' | translate | capitalizeFirst}}</span>
          <span *ngIf="!codeInput.invalid && isInvalidCode">
            {{'access-statement.errors.access-code.invalid' | translate | capitalizeFirst}}</span>
        </div>
      </div>

      <div class="d-flex flex-column flex-sm-row align-items-sm-baseline mt-3">
        <button class="btn btn-primary" (click)="checkAccessCode()" [disabled]="!accessCode || accessCode.length < accessCodeMaxLength" translate>
          access-statement.buttons.access
        </button>
        <a routerLink="/home" id="home-link"
          class="btn btn-outline-primary ml-0 ml-sm-3 mt-3 mt-sm-0 h-100 align-self-center"
          translate>access-statement.buttons.return</a>
      </div>
    </form>
  </div>
</div>