import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { GcuComponent } from '@pages/gcu/gcu.component';
import { AccessStatementComponent } from '@pages/guest/statement/access-statement/access-statement.component';
import { ShowStatementComponent } from '@pages/guest/statement/show-statement/show-statement.component';
import { MenuRoutingModule } from '@pages/routing/menu-routing.module';
import { CreateStatementComponent } from '@pages/statement/create-statement/create-statement.component';
import { DownloadSignedPdfComponent } from '@pages/statement/download-signed-pdf/download-signed-pdf.component';
import { PreviewBeforeSigningComponent } from '@pages/statement/preview-before-signing/preview-before-signing.component';
import { StatementFormComponent } from '@pages/statement/statement-form/statement-form.component';
import { StatementListComponent } from '@pages/statement/statement-list/statement-list.component';
import { EditStatementResolver } from '@resolvers/edit-statement.resolver';
import { ShowStatementResolver } from '@resolvers/show-statement.resolver';
import { StatementResolver } from '@resolvers/statement.resolver';
import { ShowStatementGuestResolver } from '@resolvers/show-statement-guest.resolver';
import { CanDeactivateGuard } from '@shared/can-deactivate/can-deactivate.guard';
import { QualifiedSignatureComponent } from '@pages/statement/qualified-signature/qualified-signature.component';
import { LegalNoticeComponent } from '@core/layout/footer/components/legal-notice/legal-notice.component';
import { CookiesComponent } from '@core/layout/footer/components/cookies/cookies.component';
import { ContactComponent } from '@core/layout/footer/components/contact/contact.component';
import { IncompatibleBrowserComponent } from '@pages/incompatible-browser/incompatible-browser';
import { BrowserGuard } from '@guards/browser.guard';

const routes: Routes = [
  { path: '', redirectTo: 'account/statement-list', pathMatch: 'full' },
  { path: 'incompatible-browser', component: IncompatibleBrowserComponent },
  {
    path: 'account',
    canActivate: [BrowserGuard],
    children: [
      {
        path: 'statement-list',
        component: StatementListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'statement-form/:id',
        component: StatementFormComponent,
        resolve: { statement: StatementResolver },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'edit-statement-form/:id',
        component: StatementFormComponent,
        resolve: { statementWithTemplate: EditStatementResolver },
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'create-statement',
        component: CreateStatementComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'download-signed-pdf/:id',
        component: DownloadSignedPdfComponent,
        resolve: { statement: ShowStatementResolver },
        canActivate: [AuthGuard],
      },
      {
        path: 'preview-before-signing/:id',
        component: PreviewBeforeSigningComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'qualified-signature/:id',
        component: QualifiedSignatureComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'gcu',
    component: GcuComponent,
    canActivate: [AuthGuard, BrowserGuard],
    data: { gcuReadOnly: false }
  },
  {
    path: 'read-gcu',
    component: GcuComponent,
    canActivate: [AuthGuard, BrowserGuard],
    data: { gcuReadOnly: true }
  },
  {
    path: 'guest',
    canActivate: [BrowserGuard],
    children: [
      {
        path: 'statement-access-code/:id',
        component: AccessStatementComponent
      },
      {
        path: 'show-statement/:id',
        component: ShowStatementComponent,
        resolve: { statement: ShowStatementGuestResolver }
      }
    ]
  },
  { path: 'legal-notice', component: LegalNoticeComponent, canActivate: [BrowserGuard] },
  { path: 'cookies', component: CookiesComponent, canActivate: [BrowserGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [BrowserGuard] },
  { path: '**', redirectTo: 'account/statement-list' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy'
    }),
    MenuRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
