import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { StatementService } from '@services/statement/statement.service';
import { CoreModule } from '@core/core.module';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { StatementTemplateContent } from '@models/statement/Statement-template-content.model';
import { StatementTemplate } from '@app/shared/models/statement/statement-template.model';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { ErrorCodes } from '@models/error/error-codes.enum';

@Injectable({providedIn: CoreModule})
export class EditStatementResolver implements Resolve<any> {
  constructor(private statementService: StatementService, private router: Router,
              private toastNotificationService: ToastNotificationService, private translateService: IngroupeTranslateService) {
  }

  public async resolve(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Promise<{ statement: StatementTemplateContent; template: StatementTemplateContent }> {
    const idStatement = route.paramMap.get('id');
    if (ObjectUtils.isNotNullOrUndefined(idStatement)) {
      try {
        const statement: StatementTemplateContent = await this.statementService.getStatement(idStatement);
        let template: StatementTemplateContent = null;
        if (StringUtils.isNotBlank(statement.templateId)) {
          const statementTpl: StatementTemplate = await this.statementService.getTemplate(statement.templateId, statement.lang);
          template = statementTpl.templateContents[0];
        }

        return {
          statement,
          template
        };
      } catch (error) {
        const errorMessage = this.translateService.resolveMessage('create-statement.error.template-not-available') as string;
        const genericErrorMessage = this.translateService.resolveMessage('create-statement.error.generic') as string;
        if (error.error.code === ErrorCodes.DECLARATION_NOT_FOUND || error.error.code === ErrorCodes.TEMPLATE_NOT_FOUND) {
          this.toastNotificationService.error(errorMessage);
        } else {
          this.toastNotificationService.error(genericErrorMessage);
        }
      }
    }

    this.router.navigate(['account/statement-list']);
    return null;
  }
}
