import { Injectable } from '@angular/core';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CoreModule } from '@core/core.module';


/**
 * Device service
 *
 * Service to call get information on browser and other
 */
@Injectable({
    providedIn: CoreModule
})
export class DeviceService {
    public deprecatedBrowserMap: Map<string, number>;

    /**
     * The constructor
     *
     * @param http : the http service use to made http request
     */
    public constructor(private deviceService: DeviceDetectorService) {
        this.deprecatedBrowserMap = new Map<string, number>();

        this.deprecatedBrowserMap.set('IE', 12);
    }

    /**
     * Check if the user's browser is deprecated
     *
     * @returns true if the user's browser is deprecated, otherwise false
     */
    public isDeprecatedBrowser(): boolean {
        const deprecatedBrowserVersion: number = this.deprecatedBrowserMap.get(this.deviceService.browser);
        const browserVersion: string = this.deviceService.browser_version;

        if (ObjectUtils.isNotNullOrUndefined(deprecatedBrowserVersion) && deprecatedBrowserVersion > 0
            && StringUtils.isNotBlank(browserVersion)) {
            const currentMajorVersion: number = parseInt(this.deviceService.browser_version.split('.')[0], 10);
            return currentMajorVersion <= deprecatedBrowserVersion;
        }

        return false;
    }

    /**
     * Check if the user is on mobile or tablet
     *
     * @returns true if the user is on mobile or tablet, otherwise false
     */
    public isMobile(): boolean {
        return this.deviceService.isMobile() || this.deviceService.isTablet();
    }
}
