import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CoreModule} from '@core/core.module';
import {ObjectUtils, StringUtils} from '@ingroupe/common-utils';
import {Pageable} from '@models/pageable/pageable.model';
import {SignedStatementContent} from '@models/statement/signed-statement-content.model';
import {SignedStatement} from '@models/statement/signed-statement.model';
import {StatementTemplateContent} from '@models/statement/Statement-template-content.model';
import {StatementTemplate} from '@models/statement/statement-template.model';
import {Statement} from '@models/statement/statement.model';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {HttpStatusCode} from '@models/error/http-status-code.enum';


@Injectable({
  providedIn: CoreModule
})
export class StatementService {

  constructor(private http: HttpClient, private config: ConfigurationService) {
  }

  public getStatementTemplates(lang: string, categoryId: string): Promise<Statement[]> {
    const path = this.config.getEndPoint('api').getStatementModels;
    const params = new HttpParams().set('lang', lang).set('categoryId', categoryId);
    return this.http.get<Statement[]>(path, {params}).toPromise();
  }

  public async getTemplate(templateId: string, lang: string) {
    const path = this.config.getEndPoint('api').getTemplate;
    const newPath = StringUtils.format(path, templateId);
    const params = new HttpParams().set('lang', lang);
    params.append('lang', lang);
    try {
      return await this.http.get<StatementTemplate>(newPath, {params}).toPromise();
    } catch (e) {
      return e.status;
    }
  }

  public async isTemplateAvailable(templateId: string, lang: string): Promise<boolean> {
    const response = await this.getTemplate(templateId, lang);

    return response !== HttpStatusCode.HTTP_STATUS_BAD_REQUEST;
  }


  public getSignedStatements(pageable: Pageable = null): Promise<SignedStatement> {
    let params: HttpParams = new HttpParams();
    if (ObjectUtils.isNotNullOrUndefined(pageable)) {
      params = pageable.toUrlParams();
    }

    const path = this.config.getEndPoint('api').getSignedStatements;
    return this.http.get<SignedStatement>(path, {params}).toPromise();
  }

  public getIdDraft(): Promise<string> {
    const path = this.config.getEndPoint('api').getIdDraft;
    return this.http.get<string>(path).toPromise();
  }

  public getStatement(statementId): Promise<StatementTemplateContent> {
    const path = this.config.getEndPoint('api').getStatement;
    const newPath = StringUtils.format(path, statementId);
    return this.http.get<StatementTemplateContent>(newPath).toPromise();
  }

  public saveStatement(requestBody: StatementTemplateContent): Promise<string> {
    const path = this.config.getEndPoint('api').saveStatement;
    return this.http.post<string>(path, requestBody).toPromise();
  }

  public editStatement(requestBody: StatementTemplateContent): Promise<string> {
    const path = StringUtils.format(this.config.getEndPoint('api').editStatement, requestBody.id);
    return this.http.put<string>(path, requestBody).toPromise();
  }

  public deleteStatement(statementId) {
    const path = this.config.getEndPoint('api').deleteStatement;
    const newPath = StringUtils.format(path, statementId);
    return this.http.delete(newPath).toPromise();
  }

  public advancedSignStatement(statementId: string, lang: string): Promise<string> {
    const path = StringUtils.format(this.config.getEndPoint('api').advancedSignStatement, statementId);
    return this.http.post<string>(path, {lang}).toPromise();
  }

  public saveDraft(requestBody: StatementTemplateContent): Promise<string> {
    const path = this.config.getEndPoint('api').saveDraft;
    return this.http.post<string>(path, requestBody).toPromise();
  }

  public editDraft(requestBody: StatementTemplateContent): Promise<string> {
    const path = this.config.getEndPoint('api').editDraft;
    const newPath = StringUtils.format(path, requestBody.id);
    return this.http.put<string>(newPath, requestBody).toPromise();
  }

  public getStatementPdf(id: string): Promise<ArrayBuffer> {
    const path = this.config.getEndPoint('api').getStatementPdf;
    const newPath = StringUtils.format(path, id);
    return this.http.get(newPath, {responseType: 'arraybuffer'}).toPromise();
  }

  /**
   * Get signed statement as guest
   *
   * @param statementId : the statement id to get
   * @returns Promise of SignedStatementContent
   */
  public getSignedStatementAsGuest(statementId): Promise<SignedStatementContent> {
    const url: string = StringUtils.format(this.config.getEndPoint('api').getSignedStatementAsGuest, statementId);
    return this.http.get<SignedStatementContent>(url).toPromise();
  }

  /**
   * Get signed statement
   *
   * @param statementId : the statement id to get
   * @returns Promise of SignedStatementContent
   */
  public getSignedStatement(statementId): Promise<SignedStatementContent> {
    const url: string = StringUtils.format(this.config.getEndPoint('api').getSignedStatement, statementId);
    return this.http.get<SignedStatementContent>(url).toPromise();
  }

  /**
   * Check the given access code to access to declaration document
   *
   * @param statementId : the declaration id
   * @param accessCode : the access code
   * @returns Promise of any
   */
  public checkAccessCode(statementId: string, accessCode: string): Promise<any> {
    let url: string = this.config.getEndPoint('api.postAccessCode');
    url = StringUtils.format(url, statementId);
    return this.http.post(url, {accessCode}).toPromise();
  }
}
