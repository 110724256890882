import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from '@components/loading-spinner/loading-spinner.component';
import { BrowserModule } from '@angular/platform-browser';
import { IngroupeTranslateModule } from '@ingroupe/translate';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CapitalizeFirstPipe } from '@shared/pipes/capitalize-first.pipe';
import { ModalConfirmComponent } from '@components/modal-confirm/modal-confirm.component';
import { OnlyNumberDirective } from '@directives/only-number.directive';
import { LockCopyPasteDirective } from '@directives/lock-copy-paste.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { AlphanumericOnlyDirective } from './directives/alphanumeric-only.directive';
import { RichTextEditorComponent } from '@components/rich-text-editor/rich-text-editor.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

/**
 * This is the shared module
 * Adding another item which is not part of the shared is not allowed
 */
@NgModule({
  declarations: [
    RichTextEditorComponent,
    LoadingSpinnerComponent,
    SidebarComponent,
    CapitalizeFirstPipe,
    ModalConfirmComponent,
    OnlyNumberDirective,
    LockCopyPasteDirective,
    UppercaseDirective,
    AlphanumericOnlyDirective
  ],
  imports: [
    BrowserModule,
    IngroupeTranslateModule,
    PerfectScrollbarModule
  ],
  providers: [ {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  exports: [
    RichTextEditorComponent,
    IngroupeTranslateModule,
    LoadingSpinnerComponent,
    SidebarComponent,
    CapitalizeFirstPipe,
    PerfectScrollbarModule,
    OnlyNumberDirective,
    LockCopyPasteDirective,
    UppercaseDirective,
    AlphanumericOnlyDirective
  ]
})
export class SharedModule {
}
