import { HttpParams } from '@angular/common/http';
import { MapUtils, ObjectUtils } from '@ingroupe/common-utils';
import { SortDirection } from './sort-direction.enum';

export class Pageable {
    /**
     * The page number
     */
    page = 0;

    /**
     * The page size
     */
    size = 10;

    /**
     * Object which represent filters
     */
    filters: any;

    /**
     * Sorts map with field name as key and direction as value
     */
    sorts: Map<string, SortDirection> = new Map<string, SortDirection>();

    /**
     * Convert this pageable object to HttpParams
     *
     * @returns HttpParams
     */
    public toUrlParams(): HttpParams {
        let params: HttpParams = new HttpParams();
        params = params.append('page', (this.page) ? this.page.toString() : '0');
        params = params.append('size', (this.size && this.size > 0) ? this.size.toString() : '10');

        if (ObjectUtils.isNotNullOrUndefined(this.filters)) {
            for (const key of Object.keys(this.filters)) {
                params = params.append(key, this.filters[key]);
            }
        }

        if (MapUtils.isNotEmpty(this.sorts)) {
            this.sorts.forEach((direction: SortDirection, field: string) => {
                params = params.append('sort', `${field}${(SortDirection.NONE !== direction) ? ',' + direction : ''}`);
            });
        }

        return params;
    }
}
