import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Only number directive
 */
@Directive({
    selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
    /**
     * Numeric regex
     */
    private regexStr = '^[0-9]*$';

    /**
     * Authorized key array
     */
    private readonly AUTHORIZED_KEYS = [
      'backspace',
      'delete',
      'tab',
      'enter',
      'capslock',
      'home',
      'end',
      'arrowleft',
      'arrowright',
      'ctrl + a',
      'ctrl + c',
      'ctrl + v',
      'ctrl + x'];

    /**
     * Authorized key array for IE
     */
    private readonly AUTHORIZED_KEYS_IE = ['del', 'left', 'right', 'add'];

    /**
     * Constructor
     *
     * @param el : the directive element
     */
    constructor(private el: ElementRef) {
    }

    /**
     * Key listener
     *
     * @param event : the keyboard event
     */
    @HostListener('keypress', ['$event']) onKeyDown(event: KeyboardEvent) {
        const e: KeyboardEvent = event as KeyboardEvent;
        let key: string = e.key;

        if (key === null || key === undefined) {
            return;
        }

        key = key.toLowerCase();

        // control key is pressed, prefix key with ctrl +
        if (e.ctrlKey === true) {
            key = 'ctrl + ' + key;
        }

        if (this.AUTHORIZED_KEYS.indexOf(key) !== -1 || this.AUTHORIZED_KEYS_IE.indexOf(key) !== -1) {
            // let it happen, don't do anything
            return;
        }

        const regEx = new RegExp(this.regexStr);
        if (!regEx.test(key)) {
            e.preventDefault();
        }
    }
}
