import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public isAuthenticated: boolean;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
  }

}
