/**
 * Html utils to formatte QuillJs textContent
 */
export class HtmlUtils {

  /**
   * Replace html balise for text formatting bold, italic, strike, underline, left/right/center/justify align
   *
   * @param html : html formatted as quilljs lib
   * @returns string : html formatted for PDF conversion in docuement API
   */
  public static formatQuillHtmlToPdfHtml(text: string): string {
    return text.replace(/<strong>/g, '<b>')
      .replace(/<\/strong>/g, '</b>')
      .replace(/<em>/g, '<i>')
      .replace(/<\/em>/g, '</i>')
      .replace(/<s>/g, '<strike>')
      .replace(/<\/s>/g, '</strike>')
      .replace(/<p class="ql-align-center">/g, '<div style="text-align: center;">')
      .replace(/<p class="ql-align-right">/g, '<div style="text-align: right;">')
      .replace(/<p class="ql-align-justify">/g, '<div style="text-align: justify;">')
      .replace(/<p/g, '<div')
      .replace(/<\/p>/g, '</div>');
  }
}
