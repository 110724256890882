<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="/statement-list"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item"><a routerLink="/account/statement-list">{{'breadcrumb.home' | translate }}</a></li>
    <li class="breadcrumb-item"><a
      routerLink="/account/create-statement">{{'breadcrumb.create' | translate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item"><a
      [routerLink]="['/account/edit-statement-form', idStatement]">{{'breadcrumb.edit' | translate | capitalizeFirst}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.preview' | translate | capitalizeFirst}}</li>
  </ol>
</nav>

<div class="d-flex flex-column">
  <h2>{{'preview-before-signing.title' | translate | capitalizeFirst}}</h2>
  <div class="text-justify">
    <div>{{'preview-before-signing.description-line1' | translate | capitalizeFirst}}</div>
    <div>{{'preview-before-signing.description-line2' | translate | capitalizeFirst}}</div>
  </div>

  <div class="col p-0 h-auto mt-3">
    <ngx-extended-pdf-viewer *ngIf="base64" [showUnverifiedSignatures]="false" [useBrowserLocale]="true"
                             [base64Src]="base64" [height]="'60vh'" [showSecondaryToolbarButton]="false"
                             [showBookmarkButton]="false"
                             [showPrintButton]="false" [showDownloadButton]="false" [showOpenFileButton]="false"
                             [contextMenuAllowed]="false" [contextMenuAllowed]="true" [ignoreKeyboard]="true">
    </ngx-extended-pdf-viewer>
  </div>

  <div class="d-flex flex-column flex-md-row  justify-content-md-end mt-3 mt-md-5">
    <button class="btn btn-outline-primary mb-2 mb-md-0"
            (click)="editStatement()">{{'preview-before-signing.return' | translate | capitalizeFirst}}</button>
    <button class="btn btn-primary ml-0 ml-md-3"
            (click)="signStatement()">{{'preview-before-signing.sign' | translate }}</button>
  </div>
</div>
