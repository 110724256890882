import { ConfigurationService } from '@services/configuration/configuration.service';
import { IngroupeTranslateConfiguration } from '@ingroupe/translate';
import { UserService } from '@services/user/user.service';
import { GcuService } from '@services/gcu/gcu.service';
import { CardReaderInitializerService } from '@ingroupe/card-reader';
import { SignatureService } from '@services/statement/signature.service';
import { AuthMode } from '@models/user/mode.enum';

/**
 * Application initializer
 *
 * @param configurationService : the configuration service instance
 * @param userService : the user service
 * @param gcuService : the gcu service
 * @param cardReaderInitializrService: the card context initializer service
 * @param signatureService : the document signature service
 */
export function initialize(
  configurationService: ConfigurationService,
  userService: UserService, gcuService: GcuService,
  cardReaderInitializrService: CardReaderInitializerService,
  signatureService: SignatureService
): () => Promise<any> {
  return () => new Promise(async (resolve, reject) => {
    await configurationService.loadGlobalConfiguration();
    await configurationService.loadEndPointConfiguration();
    await userService.getRealmInfo();

    if (userService.isAuthenticated()) {
      await gcuService.initialize(getI18nConfiguration(configurationService).availableLanguages);
      if (userService.getAuthMode() === AuthMode.CARD) { // only init card reader if auth by card
        try {
          await cardReaderInitializrService.initialize(configurationService.getConfig('hlogic'));
        } catch (e) {
          console.error('Error pending card reader context initialization then disable this authentication type...');
        }
      }
    }
    resolve(true);
  });
}

/**
 * Get the i18n configuration
 *
 * @param configurationService : the configuration service instance
 * @return the i18nConfiguration
 */
export function getI18nConfiguration(configurationService: ConfigurationService): IngroupeTranslateConfiguration {
  return configurationService.getConfig('translate');
}
