import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user/user.service';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public loginUrl: string;
  private onChangeLanguageSubscription: Subscription;

  public constructor(private userService: UserService, private translateService: IngroupeTranslateService) {
  }

  /**
   * Component initialization listener
   */
  public ngOnInit(): void {
    this.loginUrl = this.userService.getLoginUrl(this.translateService.getCurrentLang());
    this.onChangeLanguageSubscription = this.translateService.onLanguageChange.subscribe((nextLang: string) => {
      this.loginUrl = this.userService.getLoginUrl(nextLang);
    });
  }

  /**
   * On destroy component listener
   */
  public ngOnDestroy(): void {
    // DON'T REMOVE THIS METHOD ! AutoUnsubscribe use it !
  }
}
