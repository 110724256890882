import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {StatementService} from '@services/statement/statement.service';
import {CoreModule} from '@core/core.module';
import {ObjectUtils, StringUtils} from '@ingroupe/common-utils';
import {StatementTemplateContent} from '@models/statement/Statement-template-content.model';
import {StatementTemplate} from '@models/statement/statement-template.model';
import {Constant} from '@models/constant/constant';
import {SessionStorageService} from '@services/storage/session-storage.service';
import {IngroupeTranslateService} from '@ingroupe/translate';
import {ToastNotificationService} from '@services/toast-notification/toast-notification.service';
import {ErrorCodes} from '@models/error/error-codes.enum';


@Injectable({providedIn: CoreModule})
export class StatementResolver implements Resolve<any> {
  private static readonly SESSION_STORAGE_KEY_LANG = 'lang';

  constructor(private statementService: StatementService, private sessionStorageService: SessionStorageService,
              private router: Router, private toastNotificationService: ToastNotificationService,
              private translateService: IngroupeTranslateService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<StatementTemplateContent> {
    const idStatement = route.paramMap.get('id');
    const lang = this.sessionStorageService.find(StatementResolver.SESSION_STORAGE_KEY_LANG);
    console.log('resolver id: ', idStatement);
    console.log('resolver lang : ', lang);

    if (ObjectUtils.isNullOrUndefined(idStatement) || StringUtils.isBlank(lang)) {
      return Promise.resolve(null);
    } else if (idStatement === Constant.STATEMENT_FREE) {
      return {
        free: true
      };
    }

    try {
      const response: StatementTemplate = await this.statementService.getTemplate(idStatement, lang);
      delete response.templateContents[0].lang;
      const statement: StatementTemplateContent = response.templateContents[0];
      statement.free = false;
      statement.templateId = idStatement;
      statement.lang = lang;
      return statement;
    } catch (error) {
      const errorMessage = this.translateService.resolveMessage('create-statement.error.template-not-available') as string;
      const genericErrorMessage = this.translateService.resolveMessage('create-statement.error.generic') as string;
      if (error.error.code === ErrorCodes.DECLARATION_NOT_FOUND || error.error.code === ErrorCodes.TEMPLATE_NOT_FOUND) {
        this.toastNotificationService.error(errorMessage);
      } else {
        this.toastNotificationService.error(genericErrorMessage);
      }
    }

    this.router.navigate(['account/create-statement']);
    return null;
  }
}
