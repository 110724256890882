<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="/statement-list"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="/statement-list">
        {{ (isAuthenticated ? 'breadcrumb.home' : 'breadcrumb.login') | translate}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.faq' | translate | capitalizeFirst}}</li>
  </ol>
</nav>

<div class="d-flex flex-column h-100">
  <h1 class="mb-4" translate>faq.title</h1>

  <ng-container *ngFor="let item of sections">
    <ng-container [ngTemplateOutlet]="section"
      [ngTemplateOutletContext]="{sectionTitle: 'faq.section.title.' + item, sectionContent: 'faq.section.content.' + item}">
    </ng-container>
  </ng-container>

  <ng-template #section let-sectionTitle="sectionTitle" let-sectionContent="sectionContent">
    <ngb-accordion #a="ngbAccordion">
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="m-0">{{sectionTitle | translate}}</h5>
            <button ngbPanelToggle class="btn btn-sm btn-outline-primary ml-3">
              <i class="fas" [class]="opened ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          {{sectionContent | translate}}
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-template>
</div>