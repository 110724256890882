import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StringUtils } from '@ingroupe/common-utils';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { SignedStatementContent } from '@models/statement/signed-statement-content.model';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'app-show-statement',
  templateUrl: './show-statement.component.html',
  styleUrls: ['./show-statement.component.scss']
})
export class ShowStatementComponent implements OnInit, OnDestroy {
  /**
   * The route data subscription
   */
  private routeDataSubscription: Subscription;

  /**
   * The statement id passed into url parameters
   */
  statement: SignedStatementContent;

  /**
   * The statement status
   */
  statementStatus: 'expired' | 'valid';

  /**
   * Constructor
   *
   * @param route The current activated route
   * @param configService The configuration service
   * @param datePipe The date pipe format
   * @param The translate service
   */
  constructor(
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    private datePipe: DatePipe,
    private translateService: IngroupeTranslateService) {
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.statement = data.statement;
      this.statementStatus = this.statement.expired ? 'expired' : 'valid';
    });
  }

  /**
   * On destroy component listener
   */
  public ngOnDestroy(): void {
  }

  /**
   * Get the statement pdf url
   */
  getPdfUrl(): string {
    return StringUtils.format(this.configService.getEndPoint('api.getStatementPdfAsGuest'), this.statement.id);
  }

  /**
   * Build the file name for the given statement
   */
  public buildFilename(): string {
    const formatedDate: string = this.datePipe.transform(this.statement.signDate,
      this.translateService.resolveMessage('date.filename') as string);
    return `${this.statement.object}_${formatedDate}.pdf`;
  }
}
