import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SignedStatementContent } from '@models/statement/signed-statement-content.model';
import { CoreModule } from '@core/core.module';
import { ObjectUtils } from '@ingroupe/common-utils';
import { StatementService } from '@services/statement/statement.service';

@Injectable({ providedIn: CoreModule })
export class ShowStatementResolver implements Resolve<any> {
  constructor(private statementService: StatementService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SignedStatementContent> {
    const statementId: string = route.paramMap.get('id');

    if (ObjectUtils.isNullOrUndefined(statementId)) {
      return Promise.resolve(null);
    }

    return this.statementService.getSignedStatement(statementId);
  }
}
