import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { HostListener, Directive } from '@angular/core';

@Directive()
export abstract class ComponentCanDeactivateDirective {

  private ngbModalService: NgbModal;
  private i18nService: IngroupeTranslateService;
  abstract canDeactivate(): boolean;

  constructor(translateService: IngroupeTranslateService, modalService: NgbModal) {
    this.ngbModalService = modalService;
    this.i18nService = translateService;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: Event) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
