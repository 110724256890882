<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="/statement-list"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="/statement-list">{{ (isAuthenticated ? 'breadcrumb.home' : 'breadcrumb.login') | translate}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.about' | translate | capitalizeFirst}}</li>
  </ol>
</nav>
<div class="d-flex flex-column h-100">
  <h1 class="mb-4" translate>about.title</h1>
  <div>
    <p innerHTML="{{'about.content' | translate}}"></p>
  </div>
</div>
