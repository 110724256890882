import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment.prod';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { UserService } from '@services/user/user.service';
import { GcuService } from '@services/gcu/gcu.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  /**
   * The app version
   */
  appVersion: string;

  /**
   * Indicate if the user is authenticated
   */
  isAuthenticated: boolean;

  /**
   * Indicate the state of acceptance GCU
   */
  gcuAccepted: boolean;

  /**
   * Current year for copyright
   */
  year = new Date().getFullYear();

  /**
   * The state of acceptance GCU listener
   */
  gcuStateSubscription: Subscription;

  /**
   * Constructor
   */
  public constructor(private translateService: IngroupeTranslateService, private userService: UserService, private gcuService: GcuService) {
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
    this.appVersion = environment.version;
    this.gcuStateSubscription = this.gcuService.gcuState.subscribe(state => this.gcuAccepted = state);
  }

  /**
   * On destroy page listener
   */
  public ngOnDestroy(): void {

  }

}
