<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="/statement-list"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item"><a routerLink="/account/statement-list">
      {{'breadcrumb.home' | translate }}</a></li>
    <li class="breadcrumb-item"><a
      routerLink="/account/create-statement">{{'breadcrumb.create' | translate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item active" aria-current="page" *ngIf="statement.free; else otherItem">
      {{'create-statement.free-statement.object' | translate | capitalizeFirst}}</li>
    <ng-template #otherItem>
      <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
    </ng-template>
  </ol>
</nav>

<h2 *ngIf="statement.free; else otherTitle">
  {{'create-statement.free-statement.object' | translate | capitalizeFirst}}</h2>
<ng-template #otherTitle><h2>{{title | capitalizeFirst}}</h2></ng-template>

<form #statementForm="ngForm">
  <div *ngIf="statement.free" class="form-group col-12 col-md-6 px-0">
    <label for="object">{{'statement-form.label.object' | translate | capitalizeFirst}} *</label>
    <input #objectForm="ngModel" [(ngModel)]="statement.object" maxlength="50"
           [placeholder]="'create-statement.free-statement.object-placeholder' | translate | capitalizeFirst"
           [required]="statement.free" class="form-control"
           id="object" name="object" type="text">
    <div class="text-info invalid">
      <span *ngIf="(submitted || objectMandatory) && !objectValidator">
        {{'statement-form.error.invalid-input' | translate | capitalizeFirst}}</span>
    </div>
  </div>

  <div class="form-group small">
    <label>{{'statement-form.label.sender' | translate | capitalizeFirst}} *</label>

    <app-rich-text-editor
      [defaultValue]="statement.sender"
      (update)="handleSenderChange($event)"
      (isValid)="handleIsValidForm($event)"
      [placeHolder]="'statement-form.editor.placeholder.address' | translate | capitalizeFirst"
      customId="senderEditor"
      height="10em"
      [maxLines]="5"
      [invalidCountLineMessageError]="'statement-form.error.count-line' | translate | capitalizeFirst"
    ></app-rich-text-editor>

    <div class="text-info invalid">
      <div *ngIf="submitted && !senderValidator">{{'statement-form.error.invalid-input' | translate | capitalizeFirst}}</div>

    </div>
  </div>

  <div class="form-group">
    <label>{{'statement-form.label.message' | translate | capitalizeFirst}} *</label>
    <app-rich-text-editor
      [defaultValue]="statement.message"
      (update)="handleMessageChange($event)"
      (isValid)="handleIsValidForm($event)"
      [initialValue]="statement.free ? '' : statement.message"
      displayResetButton="true"
      [resetButtonText]="'statement-form.editor.reset-message' | translate | capitalizeFirst"
      customId="messageEditor"
      isAlignButtonEnabled="true"
      [maxLines]="20"
      height="13em" isResetButtonEnabled="true"
      [invalidCountLineMessageError]="'statement-form.error.count-line' | translate | capitalizeFirst"
      [placeHolder]="'statement-form.editor.placeholder.message' | translate | capitalizeFirst"></app-rich-text-editor>


    <div class="text-info invalid">
      <span *ngIf="submitted && !this.stringIsNotBlank(this.statement.message)">{{'statement-form.error.invalid-input' | translate | capitalizeFirst}}</span>
    </div>
  </div>

  <div class="form-group col-12 col-md-6 px-0">
    <label for="location">{{'statement-form.label.location' | translate | capitalizeFirst}} *</label>
    <input #locationForm="ngModel" [(ngModel)]="statement.location" maxlength="50"
           [placeholder]="'statement-form.editor.placeholder.location' | translate | capitalizeFirst"
           class="form-control" id="location" name="location" type="text" required>
    <div class="text-info invalid">
        <span
          *ngIf="submitted && !this.stringIsNotBlank(this.statement.location)">{{'statement-form.error.invalid-input' | translate | capitalizeFirst}}</span>
    </div>
  </div>

  <div class="form-group small">
    <label>{{'statement-form.label.recipient' | translate | capitalizeFirst}}</label>
    <p>{{'statement-form.notice.recipient' | translate}}</p>
    <button type="button" class="btn btn-link btn-sm mb-3" (click)="showRecipient = !showRecipient">
      <span *ngIf="!showRecipient; else deleteBtn"><i class="fas fa-plus mr-1"></i>{{'statement-form.btn.recipient.add' | translate | capitalizeFirst}}</span>
      <ng-template #deleteBtn><i class="fas fa-trash-alt mr-1"></i>{{'statement-form.btn.recipient.remove' | translate | capitalizeFirst}}</ng-template>
    </button>


    <app-rich-text-editor
      *ngIf="showRecipient"
      [defaultValue]="statement.recipient"
      (update)="handleRecipientChange($event)"
      (isValid)="handleIsValidForm($event)"
      [placeHolder]="'statement-form.editor.placeholder.address' | translate | capitalizeFirst"
      customId="recipientEditor"
      height="10em">
    </app-rich-text-editor>

    <div class="text-info invalid">

    </div>
  </div>

  <div class="text-info invalid mt-3">
    <span *ngIf="!this.isValidForm && this.submitted">
      * {{'statement-form.error.invalid-form' | translate | capitalizeFirst}}</span>
  </div>

  <div class="d-flex flex-column flex-md-row mt-3">
    <button type="button" (click)="cancel()" class="btn btn-outline-primary mb-2 mb-md-0">
      {{'statement-form.btn.action.cancel' | translate | capitalizeFirst}}</button>
    <button type="button" (click)="saveDraft(statementForm)" class="btn btn-outline-primary mb-2 mb-md-0 ml-0 ml-md-3">
      {{'statement-form.btn.action.save-draft' | translate }}</button>
    <button type="button" (click)="previewStatement(statementForm)" class="btn btn-primary ml-0 ml-md-3">
      {{'statement-form.btn.action.sign' | translate }}</button>
  </div>

</form>
