<div class="d-flex flex-column">
  <h2>{{'download-signed-pdf.title' | translate | capitalizeFirst}}</h2>
  <div class="text-justify">{{'download-signed-pdf.description' | translate}}</div>

  <div class="d-flex flex-column flex-sm-row my-3 my-sm-4">
    <a class="btn btn-link btn-primary" fileSaver [method]="'GET'" [fileName]="buildFilename()"
      [url]="pdfUrl()">{{'download-signed-pdf.btn.download-pdf' | translate | capitalizeFirst}}</a>
    <a class="btn btn-link btn-outline-primary ml-0 ml-sm-3 mt-3 mt-sm-0"
      routerLink="/account/statement-list">{{'download-signed-pdf.btn.return' | translate | capitalizeFirst}}</a>
  </div>
</div>
