import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  public isAuthenticated: boolean;
  public cookieIsActivated: boolean;

  constructor(private userService: UserService, private analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
    this.cookieIsActivated = this.analyticsService.isActive();
  }

  public updateCookieState(): void {
    this.cookieIsActivated = !this.cookieIsActivated;
    if(this.cookieIsActivated) {
      return this.analyticsService.activateAnalytics();
    }
    return this.analyticsService.deactivateAnalytics();
  }

}
