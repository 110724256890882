import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { StringUtils } from '@ingroupe/common-utils';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { SignedStatementContent } from '@app/shared/models/statement/signed-statement-content.model';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-download-signed-pdf',
  templateUrl: './download-signed-pdf.component.html',
  styleUrls: ['./download-signed-pdf.component.scss']
})
export class DownloadSignedPdfComponent implements OnInit {
  public routeDataSubscription: Subscription;
  public statement: SignedStatementContent;

  constructor(
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    private translateService: IngroupeTranslateService,
    private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.statement = data.statement;
    });
  }

  public pdfUrl(): string {
    return StringUtils.format(this.configService.getEndPoint('api.getStatementPdf'), this.statement.id);
  }

  /**
   * Build the file name for the given statement
   */
  public buildFilename(): string {
    const formatedDate: string = this.datePipe.transform(this.statement.signDate,
      this.translateService.resolveMessage('date.filename') as string);
    return `${this.statement.object}_${formatedDate}.pdf`;
  }
}
