<div class="modal-header">
  <h3 class="modal-title">{{ title | capitalizeFirst}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span class="message" *ngIf="message" [innerHtml]="message"></span>
</div>
<div class="modal-footer justify-content-end">
  <button *ngIf="btnCancel !== undefined" type="button" class="btn btn-outline-primary" (click)="dismiss()">{{ btnCancel }}</button>
  <button *ngIf="btnRefuse !== undefined" type="button" class="btn btn-outline-primary" (click)="decline()">{{ btnRefuse }}</button>
  <button *ngIf="btnAccept !== undefined" type="button" class="btn btn-primary" (click)="accept()">{{ btnAccept }}</button>
</div>
