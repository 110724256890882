import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from '@core/layout/footer/footer.component';
import { HeaderComponent } from '@core/layout/header/header.component';
import { LayoutComponent } from '@core/layout/layout.component';
import { MenuComponent } from './menu/menu.component';
import { CoreModule } from '@core/core.module';
import { ContactComponent } from '@core/layout/footer/components/contact/contact.component';
import { LegalNoticeComponent } from '@core/layout/footer/components/legal-notice/legal-notice.component';
import { CookiesComponent } from '@core/layout/footer/components/cookies/cookies.component';
import { CookieBannerComponent } from '@core/layout/cookie-banner/cookie-banner.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LayoutComponent,
    LegalNoticeComponent,
    CookiesComponent,
    ContactComponent,
    CookieBannerComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    RouterModule
  ],
  exports: [BrowserModule, RouterModule, LayoutComponent, CoreModule, SharedModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutModule {

}
