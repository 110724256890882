import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { StringUtils } from '@ingroupe/common-utils';
import { IngroupeTranslateService } from '@ingroupe/translate';

/**
 * Lang configuration service
 */
@Injectable({
  providedIn: CoreModule
})
export class LangConfigurationService {
  /**
   * Indicate if the user has change the lang
   */
  private isCustomizedLang: boolean;

  /**
   * The constructor
   *
   * @param translateService : the translate service
   */
  public constructor(private translateService: IngroupeTranslateService) {
    this.translateService.onLanguageChange.subscribe(() => {
      const url: URL = new URL(window.location.href);
      const lang: string = url.searchParams.get('lang');

      if (StringUtils.isNotBlank(lang) && !StringUtils.equalsIgnoreCase(lang, this.translateService.getCurrentLang())) {
        this.isCustomizedLang = true;
      }
    });
  }

  /**
   * Configure the default lang
   *
   * @returns Promise of any
   */
  public async configureDefaultLang(): Promise<any> {
    if (!this.isCustomizedLang) {
      const url: URL = new URL(window.location.href);
      const lang: string = url.searchParams.get('lang');
      if (StringUtils.isNotBlank(lang) && !StringUtils.equalsIgnoreCase(lang, this.translateService.getCurrentLang())) {
        console.log(`Origin lang is defined to "${lang}"`);
        const currentLang: string = await this.translateService.changeCurrentLang(lang);
        console.log(`Force usage of "${currentLang}" lang`);
      }
    }

    return Promise.resolve();
  }
}
