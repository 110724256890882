import { Injectable } from '@angular/core';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AvailableFormLang } from '@models/configuration/available-form-lang.model';
import { CoreModule } from '@core/core.module';
import { Category } from '@models/category/category.model';

@Injectable({
  providedIn: CoreModule
})
export class CategoryService {

  constructor( private http: HttpClient, private config: ConfigurationService ) {
  }

  public getCategories( lang: AvailableFormLang ): Promise<Category[]> {
    const path = `${this.config.getEndPoint('api').getCategories}`;
    const params = new HttpParams().set('lang', lang.lang);
    params.append('lang', lang.lang);
    return this.http.get<Category[]>(path, { params }).toPromise();
  }
}
