import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public isAuthenticated: boolean;
  public sections: string[];

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
    this.initSections();
  }

  /**
   * Initialize number of help section
   */
  private initSections(): void {
    this.sections = [];
    const sectionNumber = 9;
    for (let i = 1; i <= sectionNumber; i++) {
      this.sections.push(i.toString());
    }
  }

}
