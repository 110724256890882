<div class="wrapper w-100 row no-gutters d-flex">
  <div class="d-flex align-items-center justify-content-center justify-content-md-end flex-column flex-lg-row p-3 w-100">
    <div class="mx-3" innerHTML="{{'cookie-banner.information' | translate}}"></div>
    <div class="m-3 d-flex flex-column flex-md-row">
      <button class="btn btn-primary" (click)="acceptCookies()">{{'cookie-banner.accept' | translate}}</button>
    </div>
  </div>
</div>


