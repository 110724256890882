<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li>
      <a routerLink="/statement-list"><i class="fas fa-home mr-1"></i></a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="../statement-list">{{'breadcrumb.home' | translate}}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.create' | translate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2>{{'create-statement.title.page' | translate | capitalizeFirst}}</h2>
<p class="text-justify" innerHTML="{{'create-statement.notice' | translate }}"></p>

<form #statementForm="ngForm">
  <h2>{{'create-statement.title.lang-selector' | translate | capitalizeFirst}}</h2>
  <div class="form-group">
    <div class="form-check" *ngFor="let lang of availableLanguages">
      <label class="label">{{'available-langs.' + lang.lang | translate |capitalizeFirst}}
        <input type="radio" name="lang" [id]="'lang-' + lang.lang" (change)="getCategory(lang)" [value]="lang.lang">
        <span class="checkmark-radio"></span>
      </label>
    </div>
  </div>

  <div class="form-group" *ngIf="freeCategory">
    <h2>{{'create-statement.title.statement-model' | translate | capitalizeFirst}}</h2>
    <label class="m-0">{{ 'create-statement.select-label' | translate | capitalizeFirst }}</label>
    <select class="form-control" id="exampleFormControlSelect1" name="category" [(ngModel)]="categoryId"
            [ngClass]="{ 'has-placeholder': isPlaceholder}"
            (change)="getStatementTemplates($event.currentTarget.value)">
      <option disabled selected
              value="null">{{ 'create-statement.select-placeholder' | translate | capitalizeFirst }}</option>
      <option *ngFor="let category of categories" [value]="category.id">
        {{category.contents[0].label | capitalizeFirst}}</option>
      <option disabled>{{ 'create-statement.select-separator' | translate | capitalizeFirst }}</option>
      <option [value]="freeCategory.id">
        {{freeCategory.contents[0].label | capitalizeFirst}}</option>
    </select>
    <i class="mroad-ico-chevron_bas"></i>
  </div>

  <ng-container *ngIf="statements.length > 0">
    <label *ngIf="!isFreeDeclaration()">{{ 'create-statement.model-list.label' | translate | capitalizeFirst }} *</label>
    <div class="d-flex flex-column flex-sm-row">
      <div *ngIf="!isFreeDeclaration()" class="form-group category-list">
        <div class="form-check" *ngFor="let statement of statements">
          <label class="label">{{ statement.templateContents[0].object | capitalizeFirst}}
            <input type="radio" name="statement" [id]="'statementTemplate-' + statement.id" [value]="statement.id"
                   (change)="displayHelp(statement.templateContents[0].helpObject)" [(ngModel)]="templateId">
            <span class="checkmark-radio"></span>
          </label>
        </div>
      </div>

      <div class="object-help animate__fadeInLeft" *ngIf="helpMessage">
        <i class="fas fa-info-circle"></i>
        <div class="message">{{helpMessage | capitalizeFirst}}</div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="isEmptyStatementTemplate"
       innerHTML="{{'create-statement.message.no-template' | translate }}"></div>

  <a *ngIf="templateId !== null" class="btn btn-link btn-primary mt-3"
     (click)="createStatement(templateId)">{{'create-statement.btn.create' | translate}}</a>
</form>
