import {AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalConfirmComponent} from '@components/modal-confirm/modal-confirm.component';
import {ObjectUtils, StringUtils} from '@ingroupe/common-utils';
import {IngroupeTranslateService} from '@ingroupe/translate';
import {Constant} from '@models/constant/constant';
import {StatementTemplateContent} from '@models/statement/Statement-template-content.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StatementService} from '@services/statement/statement.service';
import {ToastNotificationService} from '@services/toast-notification/toast-notification.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';
import {FormCanDeactivateDirective} from '@shared/can-deactivate/form-can-deactivate.directive';
import {SessionStorageService} from '@services/storage/session-storage.service';
import {UserService} from '@services/user/user.service';
import {UserInfo} from '@models/user/user-info.model';
import {HtmlUtils} from '@utils/html.utils';

@AutoUnsubscribe()
@Component({
  selector: 'app-statement-form',
  templateUrl: './statement-form.component.html',
  styleUrls: ['./statement-form.component.scss']
})
export class StatementFormComponent extends FormCanDeactivateDirective implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private static readonly SAVE_SUCCESS_MESSAGE_KEY = 'statement-form.save-success';
  private static readonly SAVE_ERROR_MESSAGE_KEY = 'statement-form.error.save-failed';
  private static readonly TEMPLATE_UNAVAILABLE = 'statement-form.error.template-unavailable';
  private static readonly DEFAULT_LOCATION = 'Monaco';
  private static readonly SESSION_STORAGE_KEY_LANG = 'lang';
  private static readonly SESSION_STORAGE_KEY_TEMPLATE_ID = 'templateId';

  public routeDataSubscription: Subscription;
  public routeParamsSubscription: Subscription;
  public changeLangSubscription: Subscription;
  public statement: StatementTemplateContent;
  public title: string;
  public statementId: string;
  public templateId: string;
  public templateLang: string;
  public isEditionMode: boolean;
  public isValidForm: boolean;
  public submitted = false;
  public user: UserInfo;

  public resetButton: HTMLButtonElement;

  public showRecipient = false;
  public messageFromTemplate: string;
  public adressPlaceholder: string;
  public messagePlaceholder: string;
  public objectMandatory = false;

  @ViewChildren('sender') public sender;
  @ViewChildren('message') public message;
  @ViewChild('statementForm') public form: NgForm;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statementService: StatementService,
    private translateService: IngroupeTranslateService,
    private notifService: ToastNotificationService,
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService,
    private userService: UserService
  ) {
    super(translateService, modalService);
    this.user = this.userService.getUserInfo();
  }

  handleSenderChange(event: string) {
    this.statement.sender = event;
  }

  handleMessageChange(event: string) {
    this.statement.message = event;
  }

  handleRecipientChange(event: string) {
    this.statement.recipient = event;
  }

  handleIsValidForm(event: boolean) {
    // this.isValidForm = event;
  }

  get objectValidator() {
    return StringUtils.isNotBlank(this.statement.object);
  }

  get senderValidator() {
    return StringUtils.isNotBlank(this.statement.sender);
  }

  stringIsNotBlank(str): boolean {
    return StringUtils.isNotBlank(str);
  }

  dataValidators(): boolean {
    const hasValidRecipient = this.showRecipient ? this.stringIsNotBlank(this.statement.recipient) : true;
    return this.stringIsNotBlank(this.statement.object) &&
      this.stringIsNotBlank(this.statement.sender) &&
      this.stringIsNotBlank(this.statement.message) &&
      this.stringIsNotBlank(this.statement.location) &&
      hasValidRecipient;
  }

  ngOnInit(): void {
    this.isEditionMode = this.route.snapshot.routeConfig.path.includes(Constant.EDITION_MODE);
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.statementId = params.id;
    });
    this.templateLang = this.sessionStorageService.find(StatementFormComponent.SESSION_STORAGE_KEY_LANG);
    this.routeDataSubscription = this.route.data.subscribe(data => {
      if (ObjectUtils.isNotNullOrUndefined(data.statementWithTemplate)) {
        this.statement = data.statementWithTemplate.statement;
        this.isValidForm = this.statement.free ? false : true;
        this.statement.modifiedDate = undefined;

        if (ObjectUtils.isNotNullOrUndefined(data.statementWithTemplate.template)) {
          this.messageFromTemplate = data.statementWithTemplate.template.message;
        }
      } else {
        this.statement = data.statement;

        if (StringUtils.isBlank(this.statement.sender)) {
          this.statement.sender = `${this.user.name}`;
        }
        this.statement.location = StatementFormComponent.DEFAULT_LOCATION;
        this.messageFromTemplate = this.statement.message;
      }

      if (!this.statement.free) {
        this.title = this.statement.object;
      }

      delete this.statement.helpObject;
    });
  }

  ngAfterViewInit(): void {
    this.changeLangSubscription = this.translateService.onLanguageChange.subscribe(() => {
      this.adressPlaceholder = this.translateService.resolveMessage('statement-form.editor.placeholder.address') as string;
      this.messagePlaceholder = this.translateService.resolveMessage('statement-form.editor.placeholder.message') as string;
    });
  }

  ngAfterViewChecked(): void {
    this.adressPlaceholder = this.translateService.resolveMessage('statement-form.editor.placeholder.address') as string;
    this.messagePlaceholder = this.translateService.resolveMessage('statement-form.editor.placeholder.message') as string;
    // this.resetButton.innerText = this.translateService.resolveMessage('statement-form.editor.reset-message') as string;
  }

  ngOnDestroy(): void {
  }

  public saveDraft(statementForm: NgForm): void {
    const recipient: string = this.statement.recipient;
    this.prepareDataBeforeSave();

    let serviceToCall;
    if (this.isEditionMode) {
      this.statement.id = this.statementId;
      serviceToCall = this.statementService.editDraft(this.statement);
    } else {
      serviceToCall = this.statementService.saveDraft(this.statement);
    }

    if (this.objectValidator) {
      serviceToCall.then(() => {
        this.notifService.success(StatementFormComponent.SAVE_SUCCESS_MESSAGE_KEY);
        this.statement.recipient = recipient;
        this.submitted = false;
      }).catch((error: any) => {
        if (error.error.code === 'BUSI-DECLA-05' || error.error.code === 'BUSI-DECLA-04') {
          this.notifService.error(StatementFormComponent.TEMPLATE_UNAVAILABLE);
        } else {
          this.notifService.error(StatementFormComponent.SAVE_ERROR_MESSAGE_KEY);
        }
      });
    } else {
      this.objectMandatory = !this.objectValidator;
    }

  }

  public async previewStatement(statementForm: NgForm): Promise<void> {
    const isTemplateAvailable = await this.statementService.isTemplateAvailable(this.statement.templateId, this.templateLang);
    if (!isTemplateAvailable) {
      return this.notifService.error(StatementFormComponent.TEMPLATE_UNAVAILABLE);
    }
    this.sessionStorageService.save(StatementFormComponent.SESSION_STORAGE_KEY_TEMPLATE_ID, this.statement.templateId);
    this.submitted = true;
    if (this.dataValidators()) {
      this.prepareDataBeforeSave();
      let serviceToCall: Promise<string>;
      if (this.isEditionMode) {
        this.statement.id = this.statementId;
        serviceToCall = this.statementService.editStatement(this.statement);
      } else {
        serviceToCall = this.statementService.saveStatement(this.statement);
      }
      serviceToCall.then((response: any) => {
        if (response) {
          const statementId = response;
          statementForm.resetForm();
          this.sessionStorageService.save('idStatementOnDraft', statementId);
          this.router.navigate(['account/preview-before-signing', statementId]);
        }
      });
    }
  }

  /**
   * Cancel statement
   */
  public cancel(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, {size: 'md', backdrop: 'static'});
    modalRef.componentInstance.title = this.translateService.resolveMessage('statement-form.modal-confirm.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage('statement-form.modal-confirm.message');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('statement-form.modal-confirm.cancel');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('statement-form.modal-confirm.confirm');
    modalRef.result.then(() => {
      this.router.navigate(['/account/statement-list']);
    });
  }

  private prepareDataBeforeSave(): void {
    if (StringUtils.isNotBlank(this.templateLang) && StringUtils.isBlank(this.statement.lang)) {
      this.statement.lang = this.templateLang;
    }

    if (!this.showRecipient) {
      this.statement.recipient = null;
    }
    this.statement.sender =  HtmlUtils.formatQuillHtmlToPdfHtml(this.statement.sender);
    this.statement.message = HtmlUtils.formatQuillHtmlToPdfHtml(this.statement.message);
  }
}
