import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';
import { ArrayUtils } from '@ingroupe/common-utils';

export enum SlideDirection {
  TOP_TO_BOTTOM, BOTTOM_TO_TOP, LEFT_TO_RIGHT, RIGHT_TO_LEFT
}

/**
 * Slide in / Slide out animation when element is created / detroyed
 *
 * @param animationSpeed : the animation speed
 * @returns AnimationTriggerMetadata to put into component animations
 */
export function slideInOut(
  direction: SlideDirection = SlideDirection.LEFT_TO_RIGHT,
  animationSpeed: number = 600
): AnimationTriggerMetadata {
  const translateType: string = ArrayUtils.contains([SlideDirection.TOP_TO_BOTTOM, SlideDirection.BOTTOM_TO_TOP], direction)
    ? 'translateY' : 'translateX';
  const startDirection: number = ArrayUtils.contains([SlideDirection.BOTTOM_TO_TOP, SlideDirection.RIGHT_TO_LEFT], direction) ? 100 : -100;
  const endDirection = 0;
  const leaveEndDirection: number = ArrayUtils.contains([
    SlideDirection.LEFT_TO_RIGHT,
    SlideDirection.TOP_TO_BOTTOM
  ], direction) ? -100 : 100;
  const leaveAnimationType: string = (leaveEndDirection === -100) ? 'ease-in' : 'ease-out';

  return trigger('slideInOut', [
    transition(':enter', [
      style({ width: '100%', transform: `${translateType}(${startDirection}%)` }),
      animate(`${animationSpeed}ms ease-in`, style({ transform: `${translateType}(${endDirection}%)` })),
    ]),
    transition(':leave', [
      animate(`${animationSpeed}ms ${leaveAnimationType}`, style({ transform: `${translateType}(${leaveEndDirection}%)` })),
      style({ width: '0' })
    ])
  ]);
}
