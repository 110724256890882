export enum ErrorCodes {
  TEMPLATE_NOT_FOUND = 'BUSI-DECLA-04',
  DECLARATION_NOT_FOUND = 'BUSI-DECLA-05',
  INVALID_ACCES_CODE = 'BUSI-DECLA-08',
  INVALID_CERT = 'BUSI-DECLA-09',
  CERTIFICATE_EXPIRED = 'BUSI-DECLA-10',
  CERTIFICATE_REVOKED = 'BUSI-DECLA-11',
  CERTIFICATE_NOT_YET_VALID = 'BUSI-DECLA-12',
  CANNOT_DETRMINE_CERT = 'BUSI-DECLA-13',
  CHECK_IDENTITY_FAILED = 'BUSI-DECLA-15',
  TOO_MANY_CODE_ATTEMPTS = 'BUSI-DECLA-17'
}
