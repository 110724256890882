<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">
      <i class="fas fa-home mr-1"></i>
      {{'breadcrumb.home' | translate}}</li>
  </ol>
</nav>
<div>
  <div class="row col-12 col-md-8 d-flex flex-column">
    <h2>{{'statement-list.title' | translate | capitalizeFirst}}</h2>
    <div class="description text-justify"
         [innerHTML]="'statement-list.description' | translate"></div>
  </div>

  <div class="my-3" *ngIf="statementInProgress">
    <h3 class="mt-3">{{'statement-list.in-progress.title' | translate | capitalizeFirst}}</h3>
    <div>{{statementInProgress.object}}<span class="mr-3"> - {{statementInProgress.lang | uppercase}}</span>
      <span
        class="last-modificationDate">{{'statement-list.in-progress.last-modification' | translate | capitalizeFirst}} {{statementInProgress.modifiedDate | date:'dd/MM/yyyy'}}
        {{'statement-list.in-progress.at' | translate}} {{statementInProgress.modifiedDate | date:'HH:mm'}}</span>
    </div>
    <a class="link mr-3" [routerLink]="['/account/edit-statement-form', statementInProgress.id]">
      {{'statement-list.in-progress.continue-statement-link' | translate | capitalizeFirst}}</a>
    <a class="link" routerLink="#"
       (click)="deleteStatement(statementInProgress.id)"> {{'statement-list.in-progress.delete-statement-link' | translate | capitalizeFirst}}</a>
  </div>

  <button class="btn btn-primary my-3"
          (click)="createStatement()">{{'statement-list.btn.create' | translate | uppercase}}</button>

  <div class="my-3">
    <h3>{{'statement-list.list-title' | translate | capitalizeFirst}}</h3>
    <button class="btn btn-filter mb-3 demi"
            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="isCollapsed"
            aria-controls="filterCollapse">{{'statement-list.sort-by' | translate | capitalizeFirst}}
      <i *ngIf="!isCollapsed" class="fas fa-chevron-down ml-3"></i>
      <i *ngIf="isCollapsed" class="fas fa-chevron-up ml-3"></i>
    </button>
  </div>

  <form #criterionForm="ngForm">
    <div id="criterionCollapse" [ngbCollapse]="!isCollapsed" class="col-12 col-md-8 p-0">
      <div class="border rounded my-3 p-3">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h6 class="demi">{{'statement-list.sort-by' | translate | capitalizeFirst}}</h6>
              <a class="nav-link text-right"
                 (click)="resetFilters()">{{'statement-list.reset-filters' | translate | capitalizeFirst}}</a>
            </div>
            <div class="d-flex justify-content-between flex-wrap px-0 px-sm-3">
              <div class="ml-3">
                <div class="mt-2 mt-md-0">
                  {{'statement-list.sorting-criterion.expireDate' | translate | capitalizeFirst}}</div>
                <div class="form-check">
                  <label class="label" for="expireDate-asc">
                    <span>{{'statement-list.most-recent' | translate }}</span>
                    <input class="form-check-input" type="radio" name="expireDate" id="expireDate-asc"
                           [value]="sortDirection.ASC"
                           (change)="sortCriteriaChange('expireDate', sortDirection.ASC); deleteOtherSorts('expireDate')">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="label" for="expireDate-desc">
                    <span>{{'statement-list.least-recent' | translate }}</span>
                    <input class="form-check-input" type="radio" name="expireDate" id="expireDate-desc"
                           [value]="sortDirection.DESC"
                           (change)="sortCriteriaChange('expireDate', sortDirection.DESC); deleteOtherSorts('expireDate')">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
              </div>
              <div class="ml-3">
                <div class="mt-2 mt-md-0">
                  {{'statement-list.sorting-criterion.signDate' | translate | capitalizeFirst}}</div>
                <div class="form-check">
                  <label class="label" for="signDate-desc">
                    <span>{{'statement-list.most-recent' | translate }}</span>
                    <input class="form-check-input" type="radio" name="signDate" id="signDate-desc"
                           [value]="sortDirection.DESC"
                           (change)="sortCriteriaChange('signDate', sortDirection.DESC); deleteOtherSorts('signDate')">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="label" for="signDate-asc">
                    <span>{{'statement-list.least-recent' | translate }}</span>
                    <input class="form-check-input" type="radio" name="signDate" id="signDate-asc"
                           [value]="sortDirection.ASC"
                           (change)="sortCriteriaChange('signDate', sortDirection.ASC); deleteOtherSorts('signDate')">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
              </div>
              <div class="ml-3">
                <div class="mt-2 mt-md-0">
                  {{'statement-list.sorting-criterion.object' | translate | capitalizeFirst}}</div>
                <div class="form-check">
                  <label class="label" for="object-asc">
                    <span>A <i class="fas fa-long-arrow-alt-right"></i> Z</span>
                    <input class="form-check-input" type="radio" name="object" id="object-asc"
                           [value]="sortDirection.ASC"
                           (change)="sortCriteriaChange('object', sortDirection.ASC); deleteOtherSorts('object')">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="label" for="object-desc">
                    <span>Z <i class="fas fa-long-arrow-alt-right"></i> A</span>
                    <input class="form-check-input" type="radio" name="object" id="object-desc"
                           [value]="sortDirection.DESC"
                           (change)="sortCriteriaChange('object', sortDirection.DESC); deleteOtherSorts('object')">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <h3 class="text-center my-5"
      *ngIf="statements && !statements.content.length">{{'statement-list.no-document' | translate | capitalizeFirst}}</h3>
  <ng-container *ngIf="statements && statements.content.length > 0">
    <div class="statement-list col-12 col-md-8" *ngFor="let statement of statements.content">
      <div class="demi">{{statement.object}}</div>
      <div class="statement-dates">{{'statement-list.created-on' | translate | capitalizeFirst}}
        <span>{{statement.signDate | date: 'dd/MM/yyyy'}}</span></div>
      <div class="second-part d-flex justify-content-between">
        <div class="statement-dates">{{'statement-list.valid-until' | translate | capitalizeFirst}}
          <span>{{statement.expireDate | date: 'dd/MM/yyyy'}}</span></div>
        <div class="action-btn d-none d-sm-block">
          <a fileSaver [method]="'GET'" [fileName]="buildFilename(statement)" [url]="pdfUrl(statement.id)"
             (error)="handleError($event)"><i class="fas fa-cloud-download-alt"
                                              title="{{'statement-list.tooltip.download' | translate}}"></i></a>
          <i class="far fa-trash-alt ml-3" (click)="deleteStatement(statement.id)"
             title="{{'statement-list.tooltip.delete' | translate}}"></i>
        </div>

        <div ngbDropdown class="d-inline-block d-sm-none" placement="left-bottom">
          <i class="fa fa-ellipsis-v px-2" id="dropdownBasic1" ngbDropdownToggle></i>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a ngbDropdownItem fileSaver [method]="'GET'" [fileName]="buildFilename(statement)"
               (error)="handleError($event)"
               [url]="pdfUrl(statement.id)">{{'statement-list.downloadPdf' | translate | capitalizeFirst}}</a>
            <button ngbDropdownItem
                    (click)="deleteStatement(statement.id)">{{'statement-list.delete-statement' | translate | capitalizeFirst}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" *ngIf="statements && statements.totalPages > 1">
      <ngb-pagination [collectionSize]="statements.totalElements" [pageSize]="statements.pageable.pageSize"
                      [(page)]="statements.pageable.pageNumber" [maxSize]="5" [rotate]="true" size="sm"
                      [ellipses]="false"
                      (pageChange)="getStatementsList($event)">
        <ng-template ngbPaginationPrevious><i class="mroad-ico-chevron_gauche"></i></ng-template>
        <ng-template ngbPaginationNext><i class="mroad-ico-chevron_droite"></i></ng-template>
      </ngb-pagination>
    </div>
  </ng-container>
</div>
