import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FileSaverModule } from 'ngx-filesaver';
import { ToastrModule } from 'ngx-toastr';

/**
 * This is the core module
 * Adding another item which is not part of the core is not allowed.
 */
export let appInjector;

@NgModule({
  imports: [
    HttpClientModule,
    SharedModule,
    ToastrModule.forRoot({
      maxOpened: 1,
    }),
    FileSaverModule
  ],
  exports: [ SharedModule, FileSaverModule ]
})
export class CoreModule {
  constructor( private injector: Injector ) {
    appInjector = injector;
  }
}
