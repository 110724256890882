import { APP_INITIALIZER, NgModule } from '@angular/core';
import { getI18nConfiguration, initialize } from '@core/loader/application.loader';
import { LayoutComponent } from '@core/layout/layout.component';
import { PagesModule } from '@pages/pages.module';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { INGROUPE_TRANSLATE_CONFIG } from '@ingroupe/translate';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpSessionInterceptor } from '@core/interceptors/http.session.interceptor';
import { UserService } from '@services/user/user.service';
import { GcuService } from '@services/gcu/gcu.service';
import { CanDeactivateGuard } from '@shared/can-deactivate/can-deactivate.guard';
import { CardReaderInitializerService, CardReaderService } from '@ingroupe/card-reader';
import { SignatureService } from '@services/statement/signature.service';
import { MatomoModule } from 'ngx-matomo';

@NgModule({
  imports: [
    PagesModule,
    MatomoModule
  ],
  providers: [
    ConfigurationService,
    UserService,
    GcuService,
    CardReaderInitializerService,
    CardReaderService,
    SignatureService,
    { provide: APP_INITIALIZER, useFactory: initialize,
      deps: [ ConfigurationService, UserService, GcuService, CardReaderInitializerService, SignatureService ],
      multi: true },
    { provide: INGROUPE_TRANSLATE_CONFIG, useFactory: getI18nConfiguration, deps: [ ConfigurationService ] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSessionInterceptor,
      multi: true
    },
    CanDeactivateGuard
  ],
  bootstrap: [ LayoutComponent ]
})
export class AppModule {
}
