import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { CardConnection, CardReaderService } from '@ingroupe/card-reader';
import { Subscription } from 'rxjs';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { AngularUtils, ArrayUtils, ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { CodeInputComponent } from 'angular-code-input';
import { SignatureError } from '@models/signature/signature-error.enum';
import { StatementService } from '@services/statement/statement.service';
import { SignatureService } from '@services/statement/signature.service';
import {IngroupeTranslateService} from '@ingroupe/translate';

@Component({
  selector: 'app-card-identification',
  templateUrl: './qualified-signature.component.html',
  styleUrls: ['./qualified-signature.component.scss']
})
export class QualifiedSignatureComponent implements OnInit, AfterViewInit {

  private static readonly ERRORS_MAP: Map<SignatureError, string> = new Map([
    [SignatureError.INVALID_CERT_SIGN, 'signature.error.invalid-cert-sign'],
    [SignatureError.INVALID_PIN, 'signature.error.invalid-pin-code'],
    [SignatureError.NO_CARD, 'signature.error.no-card'],
    [SignatureError.NO_SIGN_CERTIFICATE, 'signature.error.no-sign-certificate'],
    [SignatureError.NO_CERTIFICATE, 'signature.error.no-certificate'],
    [SignatureError.PIN_LOCKED, 'signature.error.pin-locked'],
    [SignatureError.LAST_TRY, 'signature.error.last-try'],
    [SignatureError.CHECK_IDENTITY_FAILED, 'signature.error.check-identity-failed'],
    [SignatureError.CARD_SIGNATURE_FAILED, 'signature.error.card-signature-failed'],
    [SignatureError.CARD_INVALID_CERTIFICATE, 'signature.error.card-invalid-certificate'],
    [SignatureError.CARD_REVOKED_CERTIFICATE, 'signature.error.card-revoked-certificate']
  ]);

  statementId: string;

  /**
   * The code length
   */
  codeLength: number;

  /**
   * Indicate is pending authentication process
   */
  authenticationState: {
    pending?: boolean;
    success?: boolean;
    error?: string;
  };
  /**
   * The pin value
   */
  pin: string;

  /**
   * Show or hide pin view
   */
  displayPinView = false;

  /**
   * Show / hide pin chars
   */
  isHiddenPin = true;

  /**
   * The pin field instance
   */
  private pinField: CodeInputComponent;

  /**
   * All pin fields instance
   */
  @ViewChildren(CodeInputComponent)
  private pinFields: QueryList<CodeInputComponent>;

  /**
   * The current pin input element
   */
  private currentPinInput: HTMLInputElement;

  /**
   * The card removed listener subscription
   */
  private cardRemovedSubscription: Subscription = null;
  /**
   * The card removed listener subscription
   */
  private cardAddedSubscription: Subscription = null;

  /**
   * The pin field changed subscription
   */
  private pinFieldsChangedSubscription: Subscription = null;

  /**
   * the position saved when losing focus on the pin field
   */
  private positionToFocus: number;

  /**
   * manual lock to avoid doing twice the authentication
   */
  private pendingCode: boolean;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private sessionStorageService: SessionStorageService, private configurationService: ConfigurationService,
              private cardService: CardReaderService, private statementService: StatementService,
              private toastNotificationService: ToastNotificationService, private signatureService: SignatureService,
              private translateService: IngroupeTranslateService) {
  }

  ngOnInit(): void {
    this.statementId = this.activatedRoute.snapshot.paramMap.get('id');

    this.authenticationState = { error: null };
    this.codeLength = this.configurationService.getConfig('card.pinLength') || 4;

    this.cardRemovedSubscription = this.cardService.onCardRemoved.subscribe((cardConnection: CardConnection) => {
      this.forceResetPin();
      this.displayPinView = false;
    });

    this.cardAddedSubscription = this.cardService.onCardAdded.subscribe((cardConnection: CardConnection) => {
      this.displayPinView = true;
      this.focusOnInputPin();
    });
  }

  /**
   * After view initialized component listener
   */
  ngAfterViewInit(): void {
    const cardConnections: Array<CardConnection> = this.cardService.getCards();
    this.displayPinView = ArrayUtils.isNotEmpty(cardConnections);

    this.pinFieldsChangedSubscription = this.pinFields.changes.subscribe((components: QueryList<CodeInputComponent>) => {
      // get the pin field instance when is shown
      if (ObjectUtils.isNotNullOrUndefined(components.first)) {
        this.pinField = components.first;

        this.pinField.inputsList.forEach((field: ElementRef) => {
          const fieldInput: HTMLInputElement = field.nativeElement;
          if (ObjectUtils.isNullOrUndefined(fieldInput.onkeyup)) {
            fieldInput.onkeyup = (e: KeyboardEvent) => {
              // FIX: MIW-285 - delete char when press backspace
              if (StringUtils.equalsIgnoreCase('Backspace', e.key)) {
                fieldInput.value = '';
              }
            };
          }
        });
      }
    });
    this.focusOnInputPin();
  }

  /**
   * Display or hide pin characters
   */
  public showHidePinChars(): void {
    this.isHiddenPin = !this.isHiddenPin;
    if (ObjectUtils.isNotNullOrUndefined(this.currentPinInput)) {
      // give focus to previous selected pin input
      this.currentPinInput.focus();
      this.currentPinInput = null;
    }
    this.retrieveFocusOnNextDigit(this.pinField);
  }

  /**
   * When the pin is completely entered
   *
   * @param pin : the typed pin
   */
  public async onPinCompleted(pin: string): Promise<void> {
    if (this.pendingCode) { // when entering last 2 number of pin code fast, the event is trigger twice
      return Promise.resolve(null);
    }
    this.pendingCode = true;
    const lang = this.translateService.getCurrentLang();
    this.signatureService.doQualifiedSignature(this.statementId, pin, lang).then(() => {
      this.setStateSuccess();
      this.router.navigate(['account/download-signed-pdf', this.statementId]);
    }).catch((error: Error) => {
      this.handleSignatureError(error);
    }).finally(() => {
      this.pendingCode = false;
    });
  }

  public goToBack(): void {
    this.router.navigate(['statement/preview-before-sign', this.statementId]);
  }

  /**
   * When the pin has changed
   */
  public onPinChanged(): void {
    this.authenticationState = { error: null };
  }

  /**
   * Set the focus to the input pin
   */
  private focusOnInputPin(): void {
    AngularUtils.runAtNextTick(() => {
      this.pinField.inputsList.first.nativeElement.focus();
      this.positionToFocus = 0;
      this.isHiddenPin = true;
    });
  }

  /**
   * Save position lost during blur
   *
   * @param pinField: the pinField component
   */
  public savePositionLost(pinField): void {
    this.positionToFocus = pinField.inputs.findIndex(el => el.value === '');
  }

  /**
   * Retrieve Focus on next empty pinField
   *
   * @param pinField: the pinField component
   */
  public retrieveFocusOnNextDigit(pinField): void {
    if (ArrayUtils.isNotEmpty(pinField)) {
      pinField.inputs[this.positionToFocus].focus();
    }
  }

  /**
   * Set the state to success
   */
  private setStateSuccess(): void {
    this.authenticationState = { success: true, error: null };
  }

  /**
   * Set the state to error
   *
   * @param message : the message to display
   */
  private setStateError(message: string): void {
    this.authenticationState = { error: message };
  }

  /**
   * Force reset pin input
   */
  private forceResetPin(): void {
    this.pin = (null === this.pin) ? undefined : null;
    this.pinField.inputsList.first.nativeElement.focus();
  }

  /**
   * handle signature error
   *
   * @param error error
   *
   */
  private handleSignatureError(error): void {
    const signatureError = (error instanceof Error) ? error.message : error;
    this.setStateError('error');
    this.toastNotificationService.error(QualifiedSignatureComponent.ERRORS_MAP.get(signatureError as SignatureError));
    this.forceResetPin();
  }
}
