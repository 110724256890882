import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent {

  @Output() accept: EventEmitter<any> = new EventEmitter();
  @Output() discard: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  public acceptCookies(): void {
    this.accept.emit();

  }

  public refuseCookies(): void {
    this.discard.emit();
  }

}
