<nav class="sidebar" *ngIf="display" [@slideInOut] (@slideInOut.start)="onAnimationStart($event)" (@slideInOut.done)="onAnimationEnd($event)">
  <perfect-scrollbar>
    <header class="d-flex align-items-center p-3">
      <ng-content select="sidebar-header"></ng-content>
    </header>
    <main>
      <ng-content select="sidebar-body"></ng-content>
    </main>
    <footer>
      <ng-content select="sidebar-footer"></ng-content>
    </footer>
  </perfect-scrollbar>
</nav>

<div class="sidebar-overlay" *ngIf="showOverlay" (click)="closeSidebar()"></div>
