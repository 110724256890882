import { ComponentCanDeactivateDirective } from '@shared/can-deactivate/component-can-deactivate.directive';
import { NgForm } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive()
export abstract class FormCanDeactivateDirective extends ComponentCanDeactivateDirective {

  abstract get form(): NgForm;

  canDeactivate(): boolean {
    return this.form.submitted || !this.form.dirty;
  }
}
