import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ObjectUtils } from '@ingroupe/common-utils';
import { UserService } from '@services/user/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LayoutService } from '@services/layout/layout.service';

@Injectable({
  providedIn: CoreModule
})
export class HttpSessionInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private layoutService: LayoutService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.layoutService.displayLoadingSpinner();
    return next.handle(req).pipe(
      catchError(httpError => {
        this.layoutService.hideLoadingSpinner();
        if (httpError.status === 401 && !req.url.startsWith(UserService.REALM_BASE_URI)) {
          const matches: RegExpMatchArray = window.location.pathname.match(/\/guest\/show-statement\/([a-zA-Z0-9-]+)/);
          if (ObjectUtils.isNotNullOrUndefined(matches) && matches.length > 0) {
            // guest page to display statement then redirect to statement-access-code page
            const statementId: string = matches[1];
            const url = encodeURI(`${window.location.protocol}//${window.location.host}/guest/statement-access-code/${statementId}`);
            window.location.href = url;
          } else {
            window.location.href = this.userService.getLogoutUrl();
          }

          return;
        }
        return throwError(httpError);
      }),
      finalize(() => this.layoutService.hideLoadingSpinner())
    );
  }
}
