import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { LayoutComponent } from '@core/layout/layout.component';
import { LayoutModule } from '@core/layout/layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from '@pages/about/about.component';
import { FaqComponent } from '@pages/faq/faq.component';
import { GcuComponent } from '@pages/gcu/gcu.component';
import { AccessStatementComponent } from '@pages/guest/statement/access-statement/access-statement.component';
import { ShowStatementComponent } from '@pages/guest/statement/show-statement/show-statement.component';
import { HomeComponent } from '@pages/home/home.component';
import { AppRoutingModule } from '@pages/routing/app-routing.module';
import { CreateStatementComponent } from '@pages/statement/create-statement/create-statement.component';
import { DownloadSignedPdfComponent } from '@pages/statement/download-signed-pdf/download-signed-pdf.component';
import { PreviewBeforeSigningComponent } from '@pages/statement/preview-before-signing/preview-before-signing.component';
import { StatementFormComponent } from '@pages/statement/statement-form/statement-form.component';
import { StatementListComponent } from '@pages/statement/statement-list/statement-list.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DatePipe } from '@angular/common';
import { QualifiedSignatureComponent } from '@pages/statement/qualified-signature/qualified-signature.component';
import { CodeInputModule } from 'angular-code-input';
import { IncompatibleBrowserComponent } from '@pages/incompatible-browser/incompatible-browser';

@NgModule({
  declarations: [
    HomeComponent,
    StatementListComponent,
    CreateStatementComponent,
    StatementFormComponent,
    PreviewBeforeSigningComponent,
    DownloadSignedPdfComponent,
    GcuComponent,
    AccessStatementComponent,
    ShowStatementComponent,
    AboutComponent,
    FaqComponent,
    QualifiedSignatureComponent,
    IncompatibleBrowserComponent
  ],
  imports: [
    NgxExtendedPdfViewerModule,
    NgxDropzoneModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    LayoutModule,
    BrowserAnimationsModule,
    CodeInputModule
  ],
  exports: [LayoutComponent, CoreModule],
  providers: [DatePipe]
})
export class PagesModule { }
