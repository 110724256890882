import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatementService } from '@services/statement/statement.service';
import { StringUtils } from '@ingroupe/common-utils';
import { ConfigurationService } from '@app/core/services/configuration/configuration.service';
import { ErrorCodes } from '@models/error/error-codes.enum';
import { ToastNotificationService } from '@app/core/services/toast-notification/toast-notification.service';
import { IngroupeTranslateService } from '@ingroupe/translate';

@Component({
  selector: 'app-access-statement',
  templateUrl: './access-statement.component.html',
  styleUrls: ['./access-statement.component.scss']
})
export class AccessStatementComponent implements OnInit {
  /**
   * The success access code check redirect route
   */
  private static readonly TARGET_ROUTE: string = 'guest/show-statement';
  /**
   * Indicate if the form has been submitted
   */
  submitted: boolean;
  /**
   * The typed document access code
   */
  accessCode: string;
  /**
   * Indicate that invalid code
   */
  isInvalidCode: boolean;

  /**
   * Access code max lenght
   */
  accessCodeMaxLength: number;
  /**
   * The statement id passed into url parameters
   */
  private statementId: string;

  constructor(
    private statementService: StatementService, private configService: ConfigurationService,
    private router: Router, private route: ActivatedRoute, private translateService: IngroupeTranslateService,
    private toastNotificationService: ToastNotificationService) {
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.statementId = this.route.snapshot.paramMap.get('id');
    this.accessCodeMaxLength = this.configService.getConfig('documentDownload.accessCodeLength') || 6;
  }

  /**
   * Check the given access code
   */
  checkAccessCode(): void {
    this.submitted = true;
    if (StringUtils.isNotBlank(this.accessCode)) {
      this.statementService.checkAccessCode(this.statementId, this.accessCode)
        .then(() => this.router.navigate([AccessStatementComponent.TARGET_ROUTE, this.statementId]))
        .catch((reason) => {
          switch (reason.error.code) {
            case ErrorCodes.INVALID_ACCES_CODE:
              this.isInvalidCode = true;
              break;
            default:
              const errorMsg = this.translateService.resolveMessage('access-statement.errors.access-code.generic') as string;
              this.toastNotificationService.error(errorMsg);
          }
        });
    }
  }
}
