import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { ModalConfirmComponent } from '@components/modal-confirm/modal-confirm.component';
import { ComponentCanDeactivateDirective } from '@shared/can-deactivate/component-can-deactivate.directive';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateDirective> {

  constructor(private modalService: NgbModal, private router: Router, private translateService: IngroupeTranslateService) { }

  async canDeactivate(component: ComponentCanDeactivateDirective): Promise<boolean> {
    if (!component.canDeactivate()) {
      const modalRef = this.modalService.open(ModalConfirmComponent, { size: 'md', backdrop: 'static' });
      modalRef.componentInstance.title = this.translateService.resolveMessage('statement-form.modal-confirm.title');
      modalRef.componentInstance.message = this.translateService.resolveMessage('statement-form.modal-confirm.message');
      modalRef.componentInstance.btnRefuse = this.translateService.resolveMessage('statement-form.modal-confirm.cancel');
      modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('statement-form.modal-confirm.confirm');
      return await modalRef.result;
    }
    return true;
  }
}
