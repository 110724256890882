import { CardConnection } from '@ingroupe/card-reader';
import { CardInfo } from '@ingroupe/card-reader/lib/models/card/card-info.model';
import { ArrayUtils, ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { CardValidationConfig } from '@models/signature/card-validation-config.model';

interface ValueToCheck {
    /**
     * The value to check
     */
    value: string;

    /**
     * The validation pattern
     */
    pattern: string;
}

/**
 * Card validator utils to validate card content
 */
export class CardValidatorUtils {
    private static readonly CARD_FIELDS_TO_VALIDATE_MAP: Map<string, string> = new Map<string, string>([
        ['manufacturer', 'manufacturer'],
        ['model', 'model'],
        ['name', 'name'],
    ]);

    /**
     * Default constructor
     */
    private constructor() {

    }

    /**
     * Check if the given card connection is valid
     *
     * @param cardConnection : the card connection which contains all card informations
     * @param cardValidationConfig : the card validation configuration
     * @returns true if the card is valid otherwise false
     */
    public static isValidCard(cardConnection: CardConnection, cardValidationConfig: CardValidationConfig): boolean {
        if (ObjectUtils.isNotNullOrUndefined(cardValidationConfig)) {
            const cardInfo: CardInfo = cardConnection.cardInfo;
            if (ObjectUtils.isNotNullOrUndefined(cardInfo)) {
                const valuesToCheck: Array<ValueToCheck> = new Array<ValueToCheck>();
                CardValidatorUtils.CARD_FIELDS_TO_VALIDATE_MAP.forEach((valueKey, validatorKey) => {
                    valuesToCheck.push({ value: cardInfo[valueKey], pattern: cardValidationConfig[validatorKey] });
                });

                const badFields: Array<ValueToCheck> = valuesToCheck.filter((value: ValueToCheck) => (StringUtils.isNotBlank(value.pattern)
                        && !new RegExp(value.pattern).test(value.value)));

                if (ArrayUtils.isNotEmpty(badFields)) {
                    return false;
                }
            }
        }

        return true;
    }
}
