import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphanumericOnly]'
})
export class AlphanumericOnlyDirective {

  /**
   * Alphanumeric regex
   */
  private regexStr = '^\s*\d*^[a-zA-Z0-9]*$\d*\s*$';


  constructor() {
  }

  /**
   * Key listener
   *
   * @param event : the keyboard event
   */
  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const e: KeyboardEvent = event as KeyboardEvent;
    const key: string = e.key;

    if (key === null || key === undefined) {
      return;
    }

    const regEx = new RegExp(this.regexStr);
    if (!regEx.test(key)) {
      e.preventDefault();
    }
  }

}
