<app-loading-spinner [display]="isLoading"></app-loading-spinner>

<div class="container-fluid d-flex flex-column m-0 p-0 h-100">
  <!-- header component -->
  <app-header></app-header>

  <app-menu></app-menu>

  <!-- page content -->
  <main role="main" class="flex-grow-1 p-3 mx-0 my-3 col-12 col-lg-8 mx-auto">
    <router-outlet></router-outlet>
  </main>


  <app-cookie-banner (accept)="activateAnalytics()" (discard)="refuseAnalytics()" *ngIf="displayCookieBanner">
  </app-cookie-banner>

  <!-- footer component -->
  <app-footer></app-footer>
</div>
