import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CoreModule} from '@core/core.module';
import {CardConnection, CardReaderService, CertificateObject, Pin} from '@ingroupe/card-reader';
import {ArrayUtils, ObjectUtils, StringUtils} from '@ingroupe/common-utils';
import {CardValidationConfig} from '@models/signature/card-validation-config.model';
import {SignatureError} from '@models/signature/signature-error.enum';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {UserService} from '@services/user/user.service';
import {CardValidatorUtils} from '@utils/card-validator.utils';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ErrorCodes} from '@models/error/error-codes.enum';

/**
 * The document signature service
 */
@Injectable({
  providedIn: CoreModule
})
export class SignatureService {
  /**
   * The sign certificate type
   */
  private static readonly SIGN_CERTIFICATE_TYPE = 'Non Repudiation';
  private static readonly CA_FALSE = 'CA:FALSE';

  private pinLength: number;

  /**
   * Constructor
   *
   * @param http: the http client to make http request
   * @param configurationService: the configuration service
   * @param cardService: the card service
   * @param userService: the user service
   */
  public constructor(
    private http: HttpClient, private configurationService: ConfigurationService,
    private cardService: CardReaderService, private userService: UserService) {
  }

  /**
   * Sign the document
   *
   * @param documentUuid : the document uuid to sign
   * @param pinCode : pin code
   * @param lang: the lang of the visual signature
   * @returns Promise of any resolved if ok otherwise rejected
   */
  public async doQualifiedSignature(documentUuid: string, pinCode: string, lang: string): Promise<any> {
    let cardConnection: CardConnection;
    let signCertificate: CertificateObject;

    const cardValidationConfig: CardValidationConfig = this.configurationService.getConfig('card.validation');

    const cardConnections: Array<CardConnection> = this.cardService.getCards();
    if (ArrayUtils.isEmpty(cardConnections)) {
      console.log('Error get card connection...');
      throw new Error(SignatureError.NO_CARD);
    }

    cardConnection = cardConnections.find((eachCardConnection: CardConnection) =>
      CardValidatorUtils.isValidCard(eachCardConnection, cardValidationConfig)
    );

    if (ObjectUtils.isNullOrUndefined(cardConnection)) {
      console.log('Error get card connection matching card validation config...');
      throw new Error(SignatureError.NO_CERTIFICATE);
    }

    // find sign certificate
    signCertificate = await this.findSignCertificate(cardConnection);
    if (ObjectUtils.isNullOrUndefined(signCertificate)) {
      throw new Error(SignatureError.NO_SIGN_CERTIFICATE);
    }

    const base64Cert: string = await signCertificate.getValue();

    // get the document hash to sign
    const documentHash: Uint8Array = await this.getDocumentHash(documentUuid, base64Cert, lang);

    // check pin
    try {
      await this.cardService.login(cardConnection, signCertificate, pinCode);
    } catch (reason) {
      console.log('Error login container signature certificate...');
      const certPin: Pin = this.cardService.getCertificatePin(cardConnection, signCertificate);
      if (certPin.lastTry) {
        throw new Error(SignatureError.LAST_TRY);
      } else if (certPin.blocked) {
        throw new Error(SignatureError.PIN_LOCKED);
      } else {
        throw new Error(SignatureError.INVALID_PIN);
      }
    }

    // sign challenge
    let strSignedHash: string;
    try {
      // todo: ATTENTION :: use correct partial hash to do qualified signature
      const signedHash: Uint8Array = await this.cardService.sign(cardConnection, signCertificate, documentHash, null);
      strSignedHash = btoa(String.fromCharCode(...signedHash));
    } catch (e) {
      console.error('Error during sign document...');
      throw new Error(SignatureError.CARD_SIGNATURE_FAILED);
    } finally {
      await this.cardService.logout(cardConnection, signCertificate);
    }

    // add signature to the document
    return this.finalizeDocumentSignature(documentUuid, strSignedHash, lang);
  }

  /**
   * Find the sign certificate into the given card connection
   *
   * @param cardConnection : the card connection
   */
  private async findSignCertificate(cardConnection: CardConnection): Promise<CertificateObject> {
    const certificates: Array<CertificateObject> = this.cardService.getCertificates(cardConnection);
    if (ArrayUtils.isEmpty(certificates)) {
      return Promise.reject();
    }

    for (const cert of certificates) {
      const details: any = await cert.getDetails();
      if (ObjectUtils.isNotNullOrUndefined(details.extensions)) {
        const keyUsage: any = (details.extensions as Array<any>).find(
          ext => StringUtils.equalsIgnoreCase(ext.object.shortName, 'keyUsage')
        );
        const basicConstraints: any = (details.extensions as Array<any>).find(
          ext => StringUtils.equalsIgnoreCase(ext.object.shortName, 'basicConstraints')
        );
        if (
          ObjectUtils.isNotNullOrUndefined(keyUsage) &&
          (keyUsage.value as string).includes(SignatureService.SIGN_CERTIFICATE_TYPE) &&
          (basicConstraints.value as string).includes(SignatureService.CA_FALSE)
        ) {
          return cert;
        }
      }
    }

    return null;
  }

  /**
   * Get the document hash to sign
   *
   * @param documentUuid : the document uuid to sign
   * @param base64Cert : cert signature in base 64
   * @param lang: the lang of the visual signature
   * @returns Promise of Uint8Array which represents the challenge as byte array
   */
  private async getDocumentHash(documentUuid: string, base64Cert: string, lang: string): Promise<Uint8Array> {
    // get document hash to sign
    const url: string = StringUtils.format(
      this.configurationService.getEndPoint('api.initializeDocumentSignature'), documentUuid
    );
    const base64Challenge: string = await this.http.post(url, {certificate: base64Cert, lang},
      {responseType: 'text' as 'json'}).pipe(
      catchError((error: HttpErrorResponse): any => {
        return this.handleSignatureError(error, SignatureError.CARD_SIGNATURE_FAILED);
      }),
      map((response: string) => response)
    ).toPromise();

    return Uint8Array.from(atob(base64Challenge), c => c.charCodeAt(0));
  }

  /**
   * Finalize the document signature
   *
   * @param documentUuid : the document uuid to sign
   * @param signedHash : the signed document hash
   * @param lang : the lang
   * @returns Promise of any resolved when the document is signed otherwise rejected
   */
  private async finalizeDocumentSignature(documentUuid: string, signedHash: string, lang: string): Promise<any> {
    // get document hash to sign
    const url: string = StringUtils.format(
      this.configurationService.getEndPoint('api.finalizeDocumentSignature'), documentUuid
    );
    return this.http.post(url, {
      signature: signedHash, lang
    }).pipe(
      catchError((error: HttpErrorResponse): any => {
        return this.handleSignatureError(error, SignatureError.CARD_SIGNATURE_FAILED);
      })
    ).toPromise();
  }

  private handleSignatureError(error: HttpErrorResponse, defaultError: SignatureError): any {
    const code = 'string' === typeof error.error ?
      JSON.parse(error.error).code : error.error.code;
    switch (code) {
      case ErrorCodes.CERTIFICATE_EXPIRED:
      case ErrorCodes.CERTIFICATE_REVOKED:
      case ErrorCodes.CERTIFICATE_NOT_YET_VALID:
        return throwError(SignatureError.CARD_REVOKED_CERTIFICATE);
      case ErrorCodes.INVALID_CERT:
        return throwError(SignatureError.CARD_INVALID_CERTIFICATE);
      case ErrorCodes.CHECK_IDENTITY_FAILED:
        return throwError(SignatureError.CHECK_IDENTITY_FAILED);
      default:
        return throwError(defaultError);
    }
  }
}
