import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageSelectorDisplayType } from '@ingroupe/translate';
import { UserInfo } from '@models/user/user-info.model';
import { LayoutService } from '@services/layout/layout.service';
import { UserService } from '@services/user/user.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import {SessionStorageService} from '@services/storage/session-storage.service';
import { Router } from '@angular/router';

@AutoUnsubscribe()
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
    /**
     * Subscription to listen user info changes
     */
    private displayUserInfoChangedSubscription: Subscription;

    /**
     * Show / hide user informations
     */
    displayUserInfo: boolean;

    /**
     * The current user informations
     */
    userInfo: UserInfo;

    /**
     * The logout url
     */
    logoutUrl: string;

    /**
     * The language selector type enum
     */
    languageSelectorDisplayType = LanguageSelectorDisplayType;

    /**
     * Show / hide sidebar menu
     */
    displaySidebarMenu = false;

    /**
     * Constructor
     */
    public constructor(private userService: UserService, private layoutService: LayoutService,
                       private router: Router, private sessionStorageService: SessionStorageService) {
    }

    /**
     * On component initialization listener
     */
    public ngOnInit() {
        this.logoutUrl = this.userService.getLogoutUrl();
        this.userInfo = this.userService.getUserInfo();
        this.displayUserInfo = this.userService.isAuthenticated();

        this.displayUserInfoChangedSubscription = this.layoutService.displayUserInfoChanged.subscribe((display: boolean) => {
            if (display) {
                this.userInfo = this.userService.getUserInfo();
            }

            this.displayUserInfo = display && this.userService.isAuthenticated();
        });
    }

    /**
     * On component destroy listener
     */
    public ngOnDestroy(): void {
    }

    /**
     * Logout action
     */
    public logout(): void {
        this.sessionStorageService.clear();
        window.location.href = this.logoutUrl;
    }

    /**
     * Open the sidebar menu
     */
    public openSidebarMenu(): void {
        this.displaySidebarMenu = true;
    }

    /**
     * Close the sidebar menu
     */
    public closeSidebarMenu(): void {
        this.displaySidebarMenu = false;
    }

  public gotoDashboard(): void {
    this.router.navigate(['/signature/dashboard']);
  }

}
