import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {StatementService} from '@services/statement/statement.service';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {ModalConfirmComponent} from '@components/modal-confirm/modal-confirm.component';
import {IngroupeTranslateService} from '@ingroupe/translate';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SessionStorageService} from '@services/storage/session-storage.service';
import {UserService} from '@services/user/user.service';
import {AuthMode} from '@models/user/mode.enum';
import {ToastNotificationService} from '@services/toast-notification/toast-notification.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-preview-before-signing',
  templateUrl: './preview-before-signing.component.html',
  styleUrls: ['./preview-before-signing.component.scss']
})
export class PreviewBeforeSigningComponent implements OnInit, OnDestroy {
  private static readonly TEMPLATE_UNAVAILABLE = 'statement-form.error.template-unavailable';
  private static readonly SESSION_STORAGE_KEY_LANG = 'lang';
  private static readonly SESSION_STORAGE_KEY_TEMPLATE_ID = 'templateId';

  public routeDataSubscription: Subscription;
  public base64: string;
  public idStatement: string;
  public templateLang: string;
  public templateId: string;

  constructor(private configurationService: ConfigurationService, private route: ActivatedRoute, private router: Router,
              private modalService: NgbModal, private statementService: StatementService,
              private translateService: IngroupeTranslateService, private sessionStorageService: SessionStorageService,
              private userService: UserService, private toastNotificationService: ToastNotificationService) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.params.subscribe(params => {
      this.idStatement = params.id;
      this.getStatementPdf(this.idStatement);
    });
    this.templateLang = this.sessionStorageService.find(PreviewBeforeSigningComponent.SESSION_STORAGE_KEY_LANG);
    this.templateId = this.sessionStorageService.find(PreviewBeforeSigningComponent.SESSION_STORAGE_KEY_TEMPLATE_ID);
  }

  ngOnDestroy(): void {
  }

  public getStatementPdf(id: string) {
    this.statementService.getStatementPdf(id)
      .then((response: ArrayBuffer) => {
        this.base64 = btoa(String.fromCharCode(...new Uint8Array(response)));
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  public async signStatement() {
    const isStatementAvailable = await this.statementService.isTemplateAvailable(this.templateId, this.templateLang);
    if (!isStatementAvailable) {
      return this.toastNotificationService.error(PreviewBeforeSigningComponent.TEMPLATE_UNAVAILABLE);
    }

    if (this.userService.getAuthMode() === AuthMode.MOBILE) {
      this.advancedSignStatement();
    } else {
      this.goToQualifiedSignaturePage();
    }
  }

  public editStatement() {
    this.router.navigate(['account/edit-statement-form', this.idStatement]);
  }

  private advancedSignStatement(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, {size: 'md', backdrop: 'static'});
    modalRef.componentInstance.title = this.translateService.resolveMessage('preview-before-signing.modal-confirm.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage('preview-before-signing.modal-confirm.message');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('preview-before-signing.modal-confirm.cancel');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('preview-before-signing.modal-confirm.confirm');
    modalRef.result.then(() => {
      this.statementService.advancedSignStatement(this.idStatement, this.translateService.getCurrentLang())
        .then(() => {
          this.router.navigate(['account/download-signed-pdf', this.idStatement]);
        }).catch(() => {
        this.toastNotificationService.error(this.translateService.resolveMessage('signature.error.card-signature-failed') as string);
      });
    }, (error) => {
      if (error.error.code === 'BUSI-DECLA-05' || error.error.code === 'BUSI-DECLA-04') {
        this.toastNotificationService.error(this.translateService.resolveMessage('statement-form.error.template-unavailable') as string);
      }
      return;
    });
  }

  /**
   * go to qualified signature page
   */
  private goToQualifiedSignaturePage(): void {
    this.sessionStorageService.remove('idDocument');
    this.router.navigate(['account/qualified-signature', this.idStatement]);
  }

}
