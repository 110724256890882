import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user/user.service';
import { UserInfo } from '@models/user/user-info.model';
import { ModalConfirmComponent } from '@components/modal-confirm/modal-confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IngroupeTranslateService } from '@ingroupe/translate';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /**
   * The logo path to display
   */
  logo: string;

  /**
   * Indicate if the user is authenticated
   */
  isAuthenticated: boolean;

  /**
   * The logout url
   */
  logoutUrl: string;

  /**
   * The current user informations
   */
  userInfo: UserInfo;

  /**
   * Constructor
   *
   * @param userService : the user service
   */
  constructor(private userService: UserService, private translateService: IngroupeTranslateService, private modalService: NgbModal) {
  }

  /**
   * On init component listener
   */
  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
    this.logoutUrl = this.userService.getLogoutUrl();
    this.userInfo = this.userService.getUserInfo();
  }

  /**
   * Logout action
   */
  public logout(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.title = this.translateService.resolveMessage('header.modal-logout.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage('header.modal-logout.message');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('header.modal-logout.btn-cancel');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('header.modal-logout.btn-accept');
    modalRef.result.then(() => {
      window.location.href = this.logoutUrl;
    });
  }
}
