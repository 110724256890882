import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GcuService } from '@app/core/services/gcu/gcu.service';
import { AngularUtils } from '@ingroupe/common-utils';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { Gcu } from '@models/gcu/gcu.model';
import { LayoutService } from '@services/layout/layout.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import Scrollbar from 'smooth-scrollbar';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';
import { ToastNotificationService } from '@app/core/services/toast-notification/toast-notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GcuData } from '@models/gcu/gcu-data.model';

@AutoUnsubscribe()
@Component({
  selector: 'app-gcu',
  templateUrl: './gcu.component.html',
  styleUrls: ['./gcu.component.scss']
})
export class GcuComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * Accept gcu error message key
   */
  private static readonly ACCEPT_GCU_ERROR_MESSAGE: string = 'gcu.errors.update-failed';
  /**
   * Gcu content
   * Update only on loadGcu method. Exist to fix bug on resize method with temp variable.
   */
  gcuContentUnalterable: string;
  /**
   * Gcu content display on the template
   */
  gcuContent: string;
  /**
   * Indicate if vertical scrollbar is displayed
   */
  hasVerticalScrollbar: boolean;
  /**
   * Indicate if the gcus has been read
   */
  allGcuHasBeenRead: boolean;
  /**
   * Indicate the display state of the page (read only or acceptence)
   */
  gcuDisplayType: GcuData;
  /**
   * Indicate if gcu has been accepted
   */
  hasAcceptedGcu: boolean;

  /**
   * The language change listener subscription
   */
  private onChangeLanguageSubscription: Subscription;
  /**
   * Route subscription
   */
  private routeSubscription: Subscription;
  /**
   * The gcu content scrollbar
   */
  private gcuContentScrollbar: Scrollbar;

  public constructor(
    private gcuService: GcuService,
    private translateService: IngroupeTranslateService,
    private layoutService: LayoutService,
    private notifService: ToastNotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  /**
   * Component initialization listener
   */
  public ngOnInit(): void {
    this.routeSubscription = this.route.data.subscribe((data: GcuData) => {
      this.gcuDisplayType = data;
    });

    this.loadGcu(this.translateService.getCurrentLang());

    this.onChangeLanguageSubscription = this.translateService.onLanguageChange.subscribe((nextLang: string) => {
      this.loadGcu(nextLang);
      this.allGcuHasBeenRead = false;
      this.hasAcceptedGcu = false;
      this.gcuContentScrollbar.scrollTo(0, 0, 200, {
        callback: () => this.allGcuHasBeenRead = this.gcuContentScrollbar.limit.y === 0
      });
    });
  }

  /**
   * On destroy component listener
   */
  public ngOnDestroy(): void {
    // DON'T REMOVE THIS METHOD ! AutoUnsubscribe use it !
    Scrollbar.destroyAll();
  }

  /**
   * After view init listener
   */
  public ngAfterViewInit(): void {
    this.gcuContentScrollbar = Scrollbar.initAll({
      alwaysShowTracks: true
    })[0];

    this.gcuContentScrollbar.track.xAxis.hide();
    this.gcuContentScrollbar.addListener((status: ScrollStatus) => {
      // when resizing offset and limit are at 0
      if (!this.allGcuHasBeenRead && status.offset.y > 0) {
        this.allGcuHasBeenRead = status.offset.y >= status.limit.y;
      }
    });

    if (!this.gcuDisplayType.gcuReadOnly) {
      this.gcuContentScrollbar.contentEl.classList.add('position-absolute');
    }

    AngularUtils.runAtNextTick(() => {
      this.hasVerticalScrollbar = this.layoutService.hasVerticalScrollbar();
    });
  }

  /**
   * On window resize listener
   *
   * @param event : the window resize event
   */
  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.gcuContent = '';
    const offsetBeforeResize = this.gcuContentScrollbar.offset.y;
    AngularUtils.runAtNextTick(() => {
      this.hasVerticalScrollbar = this.layoutService.hasVerticalScrollbar();
      this.gcuContent = this.gcuContentUnalterable;
      this.gcuContentScrollbar.offset.y = offsetBeforeResize;
    });
  }

  /**
   * Checkbox accept gcu change listener
   *
   * @param event : the related event
   */
  acceptGcuChange(event): void {
    this.hasAcceptedGcu = event.target.checked;
  }

  /**
   * Accept gcu
   */
  acceptGcu(): void {
    this.gcuService.accept().then(() => {
      this.router.navigate(['account/statement-list']);
    }).catch((reason) => {
      this.notifService.error(GcuComponent.ACCEPT_GCU_ERROR_MESSAGE);
    });
  }

  /**
   * Load the gcu for given lang
   *
   * @param lang : the current selected user's lang
   */
  private loadGcu(lang: string): void {
    this.gcuService.getGcuContent(lang).then((gcu: Gcu) => {
      this.gcuContent = gcu.content;
      this.gcuContentUnalterable = gcu.content;
      this.gcuContentScrollbar.update();
      AngularUtils.runAtNextTick(() => {
        this.allGcuHasBeenRead = this.gcuContentScrollbar.offset.y >= this.gcuContentScrollbar.limit.y;
      });
    }).catch((reason) => console.error('Gcu content cannot be read...'));
  }
}
