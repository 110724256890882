import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {

  public isAuthenticated: boolean;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
  }

}
